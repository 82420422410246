<script setup>
import {computed} from "vue";
import {useStore} from "vuex";
import i18n from "@/i18n";

const locale = computed(() => {
  return i18n.global.locale.value === 'uk' ? '' : i18n.global.locale.value;
});

const store = useStore();
const comparisonPlanningsCount = computed(() => store.getters.comparisonPlanningsCount);
</script>

<template>
  <a
    :href="locale ? `/${locale}/comparison` : '/comparison'"
    class="comparison-button"
  >
    <div v-if="comparisonPlanningsCount" class="comparison-button__counter">
      {{ comparisonPlanningsCount }}
    </div>
  </a>
</template>

<style scoped>
.comparison-button {
  position: fixed;
  top: 94px;
  right: 20px;

  padding: 6px 8px;

  border-radius: 50%;
  border: 2px solid var(--blago-green);
  text-decoration: none;
  cursor: pointer;

  z-index: 10;

  transition: all 0.3s;
}

.comparison-button:hover {
  background-color: var(--blago-green);
}

.comparison-button:after {
  content: url("@/assets/images/Scales36.svg");
}

.comparison-button:hover::after {
  content: url("@/assets/images/Scales36-dark.svg");
}

.comparison-button__counter {
  position: absolute;
  top: -4%;
  right: -6%;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 16px;
  height: 16px;

  font-size: 12px;
  line-height: 12px;
  text-align: center;
  color: var(--black);
  border-radius: 50%;
  background: var(--blago-white);
}

@media (max-width: 1024px) {
  .comparison-button {
    top: 60px;
  }
}
</style>
