<script setup>
import {ref} from "vue";
import CallbackPopup from "@/components/CallbackPopup.vue";
import {useI18n} from "vue-i18n";

const { t } = useI18n();
const showCard = ref(false);
const openCard = () => {
  showCard.value = true;
  document.body.classList.add('body__modal-open');
};

const closeCard = () => {
  showCard.value = false;
  document.body.classList.remove('body__modal-open');
};
</script>

<template>
  <div>
    <button

      class="section__link button-green order-call-button tl-call-catcher"
    >
      {{ t("orderCall") }}
    </button>

    <Teleport to="body">
      <CallbackPopup
        @close="closeCard"
        :show="showCard"
      />
    </Teleport>
  </div>
</template>

<style scoped>
.order-call-button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  min-width: 213px;
  height: 54px;

  background-color: var(--blago-green);
  border-radius: 27px;

  cursor: pointer;

  transition: background-color 0.3s;
}

.order-call-button::before {
  content: url("@/assets/images/phone.png");

  width: 18px;
  height: 18px;
}

.order-call-button:hover {
  background-color: var(--blago-white);
}

@media (max-width: 1024px) {
  .order-call-button {
    margin: 0 auto 40px;
  }

  .contacts-button-without-margin .order-call-button  {
    margin-bottom: 0;
  }
}
</style>
