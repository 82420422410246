<script setup>
import {computed} from "vue";
import { breadcrumbs } from '@/router';
import {useI18n} from "vue-i18n";
import i18n from "@/i18n";

const { t } = useI18n();

const linksArr = breadcrumbs.slice(0, breadcrumbs.length - 1);
const lastElement = breadcrumbs[breadcrumbs.length - 1];

const breadcrumbsElements = [];

for (const crumb of linksArr) {
  const crumbsText = breadcrumbsElements.map(crumb => crumb.text);

  if (!crumbsText.includes(crumb.text) && crumb.text !== lastElement.text) {
    breadcrumbsElements.push(crumb);
  }
}

const locale = computed(() => {
  return i18n.global.locale.value === 'uk' ? '' : i18n.global.locale.value;
});

const getUrl = (url) => {
  if (url.length === 1) {
    return `/${locale.value}`;
  }

  const arr = url.split('/').slice(1);

  return locale.value ? `/${locale.value}/${arr.join('/')}`: `/${arr.join('/')}`;
}
</script>

<template>
  <section class="section breadcrumbs">
    <ul class="breadcrumbs__list">
      <li
        v-for="crumb in breadcrumbsElements"
        :key="crumb.text"
        class="breadcrumbs__item"
      >
        <RouterLink
          :to="getUrl(crumb.to)"
          class="breadcrumbs__link section__text"
        >
          {{ t(crumb.text) }}
        </RouterLink>
      </li>

      <li class="breadcrumbs__item--last section__text">
        {{ $route.params.newsItem || t(lastElement.text) }}
      </li>
    </ul>
  </section>
</template>

<style scoped>
</style>
