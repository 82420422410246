<script setup>
import {useI18n} from "vue-i18n";
import CardsWithIcons from "@/components/CardsWithIcons.vue";

const { t } = useI18n();

defineProps(['advantages']);
</script>

<template>
  <section class="section advantages">
    <div class="advantages__content">
      <h2 class="section__title advantages__title">
        {{ t("advantages") }}
      </h2>

      <div class="advantages__container">
        <CardsWithIcons
          v-for="advantage in advantages"
          :key="advantage.text"
          :card-item="advantage"
        />
      </div>
    </div>
  </section>
</template>

<style scoped>
.advantages {
  padding: 0 190px;
  border-bottom: 1px solid var(--alternative-brown);
}

.advantages__content {
  padding-bottom: 80px;
}

.advantages__title {
  margin-bottom: 42px;

  color: var(--black);
  text-align: center;
}

.advantages__container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 40px;
}

@media (max-width: 1024px) {
  .advantages {
    padding: 0;
  }

  .advantages__content {
    padding: 0 40px 40px;
  }

  .advantages__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .advantages__title {
    margin-bottom: 20px;
  }
}

@media (max-width: 768px) {
  .advantages__content {
    padding: 0 20px 40px;
  }

  .advantages__container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}
</style>
