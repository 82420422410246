<script setup>
import {useI18n} from "vue-i18n";

const props = defineProps(['categories', 'selectedCategories']);

const emit = defineEmits(['selectCategory']);
const { t } = useI18n();

const isActive = (category) => {
  if (props.selectedCategories.length) {
    return props.selectedCategories.includes(`${category}`);
  }

  return false;
};

const handleSelectCategory = (category) => {
  emit('selectCategory', category);
};
</script>

<template>
  <div class="characteristics-buttons">
    <h3 class="section__link characteristics-buttons__title">
      <slot></slot>
    </h3>

    <div class="characteristics-buttons__wrapper">
      <button
        v-for="category in categories"
        :key="category"
        @click="handleSelectCategory(category)"
        :class="{ 'characteristics-buttons__item--active': isActive(category) }"
        class="section__text characteristics-buttons__item"
      >
        {{ category === 'price' ? t("cost") : t(category) }}
      </button>
    </div>
  </div>
</template>

<style scoped>
.characteristics-buttons {
  margin-bottom: 40px;
}

.characteristics-buttons__title {
  margin-bottom: 10px;
}

.characteristics-buttons__wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  min-width: 200px;
}

.characteristics-buttons__item {
  padding: 5px 15px;

  color: var(--black);
  border-radius: 22px;
  border: 2px solid var(--alternative-brown);
  background-color: var(--alternative-brown);

  transition: all 0.3s;
  cursor: pointer;
}

.characteristics-buttons__item:hover {
  border: 2px solid var(--blago-green);
  background-color: var(--blago-white);
}

.characteristics-buttons__item--active {
  border: 2px solid var(--blago-green);
  background-color: var(--blago-white);
}

@media (max-width: 1024px) {
  .characteristics-buttons__title {
    text-align: center;
  }

  .characteristics-buttons__wrapper {
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .characteristics-buttons__title {
    text-align: center;
  }

  .characteristics-buttons__wrapper {
    justify-content: center;
  }
}
</style>
