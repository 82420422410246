<script setup>
import {computed, onMounted, onUnmounted, ref, watch} from "vue";
import SwiperCore, {Autoplay, Keyboard, Mousewheel} from "swiper";
import {Swiper, SwiperSlide} from "swiper/vue";
import i18n from "@/i18n";
import TopAction from "@/components/TopAction.vue";
import CallButton from "@/components/CallButton.vue";
import CallbackPopup from "@/components/CallbackPopup.vue";
import PagesNum from "@/components/PagesNum.vue";
import ButtonsForMobile from "@/components/ButtonsForMobile.vue";
import ConnectForm from "@/components/ConnectForm.vue";
import LocationButton from "@/components/LocationButton.vue";

SwiperCore.use([Keyboard, Mousewheel]);

const props = defineProps(['slides', 'buttons', 'map_image', 'address']);

const locale = computed(() => {
  return i18n.global.locale.value === 'uk' ? '' : i18n.global.locale.value;
});

const slides = ref(props.slides);

watch(props, () => {
  slides.value = props.slides;
})

const showForm = ref(false);
const swiperRef = ref(null);
const slideNum = ref(1);
const totalSlidesNum = computed(() => {
  return props.slides.length || 1;
});

function onSwiperInit (swiper) {
  swiperRef.value = swiper;
}

const updateNum = () => {
  slideNum.value = swiperRef.value.realIndex + 1;
};

const openForm = () => {
  showForm.value = true;
  document.body.classList.add('body__modal-open');
};

const closeForm = () => {
  showForm.value = false;
  document.body.classList.remove('body__modal-open');
};

const windowWidth = ref(window.innerWidth);

const handleResize = () => {
  windowWidth.value = window.innerWidth;
};

onMounted(() => {
  window.addEventListener('resize', handleResize);
})

onUnmounted(() => {
  window.removeEventListener('resize', handleResize);
})
</script>

<template>
  <header class="section header">
    <TopAction />

    <div class="slider__content">
      <Swiper
        :modules="[Autoplay]"
        :autoplay="{
          delay: 2500,
          disableOnInteraction: false,
        }"
        :slides-per-view="1"
        :speed="1000"
        :keyboard="{
          enabled: true,
          onlyInViewport: true,
          pageUpDown: true,
        }"
        @slide-change="updateNum"
        @swiper="onSwiperInit"
        class="slider__slides"
      >

        <SwiperSlide
          v-for="slide in slides"
          :key="slide.text"
        >
          <div class="header__slide">
            <div class="header__content">
              <h3
                  :class="{ 'header__title--for-project': map_image }"
                  class="page__title header__title"
                  v-html="slide.text"
              />

              <p class="header__text">
                {{ slide.description }}
              </p>

              <LocationButton
                  v-if="map_image"
                  :address="address"
                  :map_image="map_image"
              />

              <div class="header__buttons-wrapper">

                <a v-if="slide['button_type'] === 'phonet' && slide['button_text'].length > 0"
                  class="header__button tl-call-catcher"
                >
                  {{ slide['button_text'] }}
                </a>

                <a v-if="slide['button_type'] === 'custom_form' && slide['button_text'].length > 0"
                  class="header__button "
                   @click="openForm"
                >
                  {{ slide['button_text'] }}
                </a>

                <a v-if="slide['button_type'] === 'url' && slide['button_text'].length > 0"
                  :href="`${slide['button_url']}`"
                  class="header__button"
                >
                  {{ slide['button_text'] }}
                </a>

<!--                <button-->
<!--                    @click="openForm"-->
<!--                    class="header__button"-->
<!--                >-->
<!--                  {{ slide['button_text'] }}-->
<!--                </button>-->

                <Teleport to="body">
                  <ConnectForm
                      @close="closeForm"
                      :show="showForm"
                  />
                </Teleport>

                <LocationButton v-if="windowWidth > 768 && map_image" :map_image="map_image" />
              </div>
            </div>

            <div class="header__photo-wrapper">

              <img
                v-if="slide.photo && slide.photo.length"
                :src="slide.photo"
                alt="blago"
                class="header__photo"
              >

              <PagesNum
                  v-if="slides.length > 1"
                  :slide-num="slideNum"
                  :total-slides-num="totalSlidesNum"
                  class="header__page-number"
              />
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>

    <CallButton />
  </header>
</template>

<style scoped>
  .header {
    height: 928px;

    background-color: var(--blago-green);
  }

  .header__content {
    padding: 0 108px 0 0;
  }

  .header__slide {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .header__title {
    margin-bottom: 30px;
  }

  .header__title--for-project {
    margin-bottom: 10px;
  }

  .header__text {
    max-width: 598px;
    margin-bottom: 30px;

    font-size: 20px;
    font-weight: 400;
    line-height: 145%;
  }

  .header__buttons-wrapper {
    display: flex;
    gap: 10px;
  }

  .header__button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 220px;
    height: 49px;

    font-size: 16px;
    font-weight: 700;
    line-height: 145%;
    color: var(--blago-black);
    text-decoration: none;

    background-color: var(--blago-green);
    border-radius: 70px;
    border: 2px solid var(--black);

    cursor: pointer;

    transition: border-color 0.3s;
  }

  .header__button:hover {
    border-color: var(--blago-white);
  }

  .header__button:active {
    border-color: var(--blago-white);
  }

  .header__photo-wrapper {
    position: relative;
    height: 928px;
    z-index: 1;
  }

  .header__photo {
    width: 100%;
    height: 100%;

    object-fit: cover;
    object-position: center;
  }

  .header__page-number {
    position: absolute;
    right: 36px;
    bottom: 10px;
    z-index: 3;

    color: var(--blago-white);
  }

  @media (max-width: 1920px) {
    .header__title {
      font-size: 56px;
    }
  }

  @media (max-width: 1440px) {
    .header__title {
      font-size: 42px;
    }
  }

  @media (max-width: 1024px) {
    .header {
      height: 100%;
      padding-left: 0;
    }

    .header__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px 20px 0;
    }

    .header__slide {
      flex-direction: column-reverse;
    }

    .header__title {
      text-align: center;
    }

    .header__title--for-project {
      margin-bottom: 15px;
    }

    .header__text {
      display: none;
    }

    .header__button {
      margin-bottom: 40px;
    }

    .header__photo-wrapper {
      width: 100%;
      height: 928px;
    }

    .header__page-number {
      right: 20px;
    }
  }

  @media (max-width: 768px) {
    .header {
      min-height: 80vh;
    }

    .header__title {
      font-size: 32px;
    }

    .header__photo-wrapper {
      height: 564px;
    }
  }
</style>
