<script setup>
import {computed} from "vue";
import {useI18n} from "vue-i18n";
import i18n from "@/i18n";

defineProps(['isActive']);

const { t } = useI18n();

const locale = computed(() => {
  return i18n.global.locale.value === 'uk' ? '' : i18n.global.locale.value;
});
</script>

<template>
  <div class="dropdown">
    <div>
      <div
        :class="{ 'nav__link--active': isActive }"
        class="section__link nav__link dropdown-title"
      >
        <slot></slot>
      </div>

      <ul class="dropdown__list">
        <li class="dropdown__list-item">
          <a
            href="https://blago-comfort.com.ua/"
            target="_blank"
            class="section__link dropdown__link"
          >
            blago comfort
          </a>
        </li>
        <li class="dropdown__list-item">
          <a
              href="https://blagoinvest.com.ua/"
              target="_blank"
              class="section__link dropdown__link"
          >
            blago invest
          </a>
        </li>
        <li class="dropdown__list-item">
          <RouterLink :to="{ name: 'service', params: { locale } }" class="section__link dropdown__link">
            {{ `blago ${t("service")}` }}
          </RouterLink>
        </li>

        <li class="dropdown__list-item">
          <RouterLink :to="{ name: 'family', params: { locale } }" class="section__link dropdown__link">
            blago family
          </RouterLink>
        </li>

        <li class="dropdown__list-item">
          <RouterLink :to="{ name: 'app-page', params: { locale } }" class="section__link dropdown__link">
            {{ `blago ${t("app")}` }}
          </RouterLink>
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped>
.dropdown {
  position: relative;

  min-width: 142px;
}

.dropdown:hover .dropdown__list {
  opacity: 1;
  visibility: visible;

  background-color: rgba(0, 0, 0, 0.6);
}

.dropdown-title {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;

  padding: 11px 24px;

  color: var(--blago-white);
  text-decoration: none;
  border-radius: 24px;

  //transition: all 0.3s;
  transition: none;

  cursor: pointer;
}

.dropdown-title:hover {
  border-color: var(--blago-white);
}

.dropdown:hover {
  border: none;
}

.dropdown:hover .dropdown-title {
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 24px 24px 0 0;
  border: none;
}

.dropdown-title::after {
  content: url("@/assets/images/arrow-down.svg");
}

.dropdown__list {
  position: absolute;
  padding: 0 2px 11px;
  width: 100%;
  text-align: center;

  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 0 0 24px 24px;

  opacity: 0;
  visibility: hidden;
  transition: border-color 0.3s;

  list-style: none;
}

.dropdown__list-item {
  padding: 11px;

  border-radius: 24px;
  border: 1px solid transparent;
  cursor: pointer;

  transition: all 0.5s;
}

.dropdown__list-item:hover {
  border-color: var(--blago-white);
}

.dropdown__link {
  color: var(--blago-white);
  text-align: center;
  text-decoration: none;
}

@media (max-width: 1660px) {
  .dropdown__link {
    font-size: 12px;
  }

  .dropdown__list-item {
    padding: 6px;
  }
}

@media (max-width: 1360px) {
  .dropdown__link {
    font-size: 10px;
  }

  .dropdown__list-item {
    padding: 6px;
  }
}

@media (max-width: 1140px) {
  .dropdown__list-item {
    padding: 4px;
  }
}
</style>
