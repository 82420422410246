<script setup>
import {computed, onMounted, onUnmounted, ref} from "vue";
import router from "@/router";
import {useRoute} from "vue-router";
import i18n from "@/i18n";
import {useI18n} from "vue-i18n";
import Nav from "@/components/Nav.vue";
import Menu from "@/components/Menu.vue";
import CallbackPopup from "@/components/CallbackPopup.vue";
import ComparisonButton from "@/components/ComparisonButton.vue";
import {useStore} from "vuex";

const { t } = useI18n();
const windowWidth = ref(window.innerWidth);

const handleResize = () => {
  windowWidth.value = window.innerWidth;
};

onMounted(() => {
  window.addEventListener('resize', handleResize);
})

onUnmounted(() => {
  window.removeEventListener('resize', handleResize);
})

const showCard = ref(false);

const openCard = () => {
  showCard.value = true;
  document.body.classList.add('body__modal-open');
};

const closeCard = () => {
  showCard.value = false;
  document.body.classList.remove('body__modal-open');
};

const route = useRoute();
const locale = computed(() => {
  return i18n.global.locale.value === 'uk' ? '' : i18n.global.locale.value;
});

const switchLanguage = (lang) => {
  i18n.global.locale.value = lang;

  if (lang === 'uk') {
    router.push({
      params: {
        ...route.params,
        locale: '',
      }
    });
  } else {
    router.push({
      params: {
        ...route.params,
        locale: lang,
      }
    });
  }
};

const store = useStore();
const comparisonPlanningsCount = computed(() => store.getters.comparisonPlanningsCount);
</script>

<template>
  <div class="top-action">
    <ComparisonButton v-if="comparisonPlanningsCount" />

    <div class="top-action__left-block-wrapper">
      <a :href="locale ? `/${locale}` : '/'">
        <img
          src="@/assets/images/header-logo-green.png"
          alt="logo"
          class="logo"
        >
      </a>

      <a href="tel:380970714000">
        <img
          src="@/assets/images/phone-icon.svg"
          alt="phone"
          class="top-action__phone-icon ph-phone1"
        >
      </a>

<!--      <div class="top-action__lang-buttons">-->
<!--        <button-->
<!--          @click="switchLanguage('uk')"-->
<!--          :class="{'top-action__lang-button&#45;&#45;active': i18n.global.locale.value === 'uk'}"-->
<!--          class="top-action__lang-button top-action__lang-button&#45;&#45;after"-->
<!--        >-->
<!--          Укр-->
<!--        </button>-->

<!--        <button-->
<!--          @click="switchLanguage('en')"-->
<!--          :class="{'top-action__lang-button&#45;&#45;active': i18n.global.locale.value === 'en'}"-->
<!--          class="top-action__lang-button"-->
<!--        >-->
<!--          Eng-->
<!--        </button>-->
<!--      </div>-->
    </div>

    <Nav />

    <div class="top-action__links-wrapper">
      <a href="tel:380970714000" target="_blank" class="top-action__link ph-phone1">
        +38 (097) 0714-000 <br>
      </a>

      <div
        class="top-action__link top-action__link--green tl-call-catcher"
      >
        {{ t("callYou") }}
    </div>
    </div>

    <Teleport to="body">
      <CallbackPopup
        @close="closeCard"
        :show="showCard"
      />
    </Teleport>

    <Menu v-if="windowWidth <= 1024" />
  </div>
</template>

<style scoped>
  .top-action {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;

    display: flex;
    justify-content: space-between;

    height: 250px;
    padding: 24px 20px;

    background: linear-gradient(180deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.00) 100%);
    z-index: 2;
  }

  .top-action__left-block-wrapper {
    display: flex;
    gap: 20px;
    height: 28px;
  }

  .logo {
    width: 126px;
    height: 28px;
  }

  .top-action__links-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .top-action__link {
    font-size: 14px;
    font-weight: 700;
    color: var(--blago-white);
    text-align: right;
    text-decoration: none;

    cursor: pointer;
  }

  .top-action__link--green {
    color: var(--blago-green);
    border-bottom: 1px dashed var(--blago-green);

    transition: all 0.3s;
  }

  .top-action__link--green:hover {
    color: var(--blago-white);
    border-bottom: 1px dashed var(--blago-white);
  }

  .top-action__phone-icon {
    display: none;
  }

  .top-action__lang-buttons {
    display: flex;
    align-self: flex-end;
    gap: 10px;
  }

  .top-action__lang-button {
    padding: 0;

    font-size: 14px;
    font-weight: 700;
    letter-spacing: -0.28px;
    color: rgba(255, 255, 255, 0.5);
    background-color: transparent;
    border: none;

    cursor: pointer;
  }

  .top-action__lang-button--active {
    color: rgba(255, 255, 255, 1);
  }

  .top-action__lang-button--after {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .top-action__lang-button--after::after {
    content: "";

    display: inline-block;
    margin: 0 9px;
    width: 19px;
    height: 2px;

    background-color: var(--blago-white);
  }

  @media (max-width: 1360px) {
    .top-action__left-block-wrapper {
      gap: 4px;
    }

    .top-action__lang-buttons {
      margin-right: 4px;
    }

    .top-action__lang-button {
      font-size: 12px;
    }

    .top-action__lang-button--after {
      gap: 4px;
    }

    .top-action__link {
      font-size: 12px;
    }

    .top-action__lang-button--after::after {
      content: "";

      margin: 0 4px;
      width: 12px;
    }

    .logo {
      width: 115px;
      height: 26px;
      margin: 0;
    }
  }

  @media (max-width: 1140px) {
    .top-action__link {
      font-size: 10px;
    }
  }

  @media (max-width: 1024px) {
    .top-action {
      height: 140px;
      padding: 20px;
    }

    .top-action__left-block-wrapper {
      gap: 25px;
    }

    .logo {
      width: 115px;
      height: 26px;
      margin: 0;
    }

    .top-action__link {
      display: none;
    }

    .top-action__phone-icon {
      display: block;
      width: 18px;
      height: 18px;
    }

    .top-action__lang-buttons {
      align-self: flex-start;
      margin-right: 10px;
    }
  }
</style>
