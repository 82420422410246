<script setup>
import AeroTourPopup from "@/components/AeroTourPopup.vue";
import CallbackPopup from "@/components/CallbackPopup.vue";
import {useI18n} from "vue-i18n";
import {ref} from "vue";

const props = defineProps(['project', 'selectedCharacteristics']);
const emit = defineEmits(['deletePlanning']);
const { t } = useI18n();

const showCard = ref(false);

const openCard = () => {
  showCard.value = true;
  document.body.classList.add('body__modal-open');
};

const closeCard = () => {
  showCard.value = false;
  document.body.classList.remove('body__modal-open');
};

const showAeroTour = ref(false);

const openAeroTour = () => {
  showAeroTour.value = true;
  document.body.classList.add('body__modal-open');
};

const closeAeroTour = () => {
  showAeroTour.value = false;
  document.body.classList.remove('body__modal-open');
};

const show3DTour = ref(false);

const open3DTour = () => {
  show3DTour.value = true;
  document.body.classList.add('body__modal-open');
};

const close3DTour = () => {
  show3DTour.value = false;
  document.body.classList.remove('body__modal-open');
};

const handleDeletePlanning = () => {
  emit('deletePlanning', props.project);
};

const characteristicValue = (characteristic) => {
  if (characteristic === 'square') {
    return `${props.project[characteristic]} ${t("squareMeasure")}`;
  }

  if (characteristic === 'price') {
    return `${t("from")} ${props.project[characteristic].toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} ${t("measure")}`;
  }

  if (characteristic === 'building_step') {
    return `${t(props.project[characteristic])}`;
  }

  if (characteristic === 'eoselia') {
    return props.project.eoselia ? `${t("yes")}` : `${t("no")}`;
  }

  if (characteristic === 'rooms_count' && props.project.free_planning) {
    return `${t("free_planning")}`;
  }

  return props.project[characteristic];
};
</script>

<template>
  <div class="planning-card">
    <div class="planning-card__image-wrapper">
      <img
        :src="props.project.planning_img"
        :alt="props.project.project_name"
        class="planning-card__image"
      />
    </div>

    <button @click="handleDeletePlanning" class="planning-card__button">
      {{ t("deleteFromComparison") }}
    </button>

    <ul class="planning-card__list">
      <li
        v-for="characteristic in selectedCharacteristics"
        :key="characteristic"
        class="planning-card__item"
      >
        {{ characteristicValue(characteristic) }}
      </li>
    </ul>

    <div class="apartment-card__buttons-wrapper planning-card__buttons">
      <button class="section__link apartment-card__button tl-call-catcher">
        {{ t("buy") }}
      </button>

      <button
        v-if="project['3d_url'] && project['3d_url'].length"
        @click="open3DTour"
        class="apartment-card__button-with-icon apartment-card__button--3d"
      >
        {{ t("3DTour") }}
      </button>

      <button
        v-if="project['aero_flyover_url'] && project['aero_flyover_url'].length"
        @click="openAeroTour"
        class="apartment-card__button-with-icon apartment-card__button--aero"
      >
        {{ t("aeroTour") }}
      </button>

      <Teleport to="body">
        <AeroTourPopup
          @close="closeAeroTour"
          :show="showAeroTour"
          :url="project['aero_flyover_url']"
        />
      </Teleport>

      <Teleport to="body">
        <AeroTourPopup
          @close="close3DTour"
          :show="show3DTour"
          :url="project['3d_url']"
        />
      </Teleport>

      <Teleport to="body">
        <CallbackPopup
          @close="closeCard"
          :show="showCard"
        />
      </Teleport>
    </div>
  </div>
</template>

<style scoped>
.planning-card {
  margin-bottom: 40px;
}

.planning-card__image-wrapper {
  position: relative;

  width: 360px;
  height: 237px;
  margin: 0 40px 8px 0;

  transition: all 0.3s;
}

.planning-card__image {
  position: relative;
  width: 100%;
  height: 100%;

  object-position: center;
  object-fit: cover;
  border-radius: 8px;
}

.planning-card__image-wrapper:hover {
  transform: scale(150%);
  transform-origin: top left;
  z-index: 10;
}

.planning-card__button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;

  margin-bottom: 20px;
  padding: 8px 16px;

  font-size: 12px;
  font-weight: 400;
  color: rgba(25, 25, 25, 0.25);
  border-radius: 18px;
  border: 2px solid var(--blago-gray);
  background-color: var(--blago-white);

  cursor: pointer;
  transition: all 0.3s;
}

.planning-card__button:hover {
  color: var(--blago-black);
  border-color: var(--blago-green);
}

.planning-card__button::after {
  content: url("@/assets/images/XCircle.svg");
  display: flex;
  justify-content: center;
  align-items: center;
}

.planning-card__button:hover::after {
  content: url("@/assets/images/XCircleBlack.svg");
}

.planning-card__list {
  padding: 0;
  list-style: none;
}

.planning-card__item {
  height: 44px;
  margin-bottom: 20px;
  padding: 10px 0;

  font-size: 16px;
  font-weight: 400;
  line-height: 145%;
  color: var(--blago-black);
  border-bottom: 1px solid var(--blago-gray);
}

.planning-card__buttons {
  margin: 0;
}

@media (max-width: 768px) {
  .planning-card__image-wrapper {
    position: relative;

    width: 100%;
    margin: 0 20px 8px 0;
  }
}
</style>
