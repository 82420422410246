import {createI18n} from "vue-i18n";
import en from "@/locales/en.json";
import uk from "@/locales/uk.json";

export function loadLocaleMessages() {
    const locales = [{ en: en }, { uk: uk }];
    const messages = {};

    locales.forEach(lang => {
        const key = Object.keys(lang);

        messages[key] = lang[key];
    });

    return messages;
}

const i18n = createI18n({
    legacy: false,
    locale: 'uk',
    fallbackLocale: 'uk',
    messages: loadLocaleMessages(),
});

export default i18n;
