<script setup>
import MapPopup from "@/components/pages/Projects/Components/MapPopup.vue";
import {useI18n} from "vue-i18n";
import {ref} from "vue";

const props = defineProps(['map_image', 'address']);

const { t } = useI18n();

const showMap = ref(false);

const openMap = () => {
  showMap.value = true;
  document.body.classList.add('body__modal-open');
};

const closeMap = () => {
  showMap.value = false;
  document.body.classList.remove('body__modal-open');
};
</script>

<template>
  <p @click="openMap" class="section__link location-button">
    <svg width="18" height="23" viewBox="0 0 18 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.625 5.125C7.80915 5.125 7.01163 5.36693 6.33327 5.82019C5.65492 6.27345 5.12621 6.91769 4.814 7.67143C4.50179 8.42518 4.4201 9.25458 4.57926 10.0547C4.73843 10.8549 5.13129 11.5899 5.70818 12.1668C6.28508 12.7437 7.02008 13.1366 7.82025 13.2957C8.62042 13.4549 9.44982 13.3732 10.2036 13.061C10.9573 12.7488 11.6016 12.2201 12.0548 11.5417C12.5081 10.8634 12.75 10.0658 12.75 9.25C12.7488 8.15636 12.3138 7.10787 11.5404 6.33455C10.7671 5.56124 9.71864 5.12624 8.625 5.125ZM8.625 11.125C8.25416 11.125 7.89165 11.015 7.58331 10.809C7.27496 10.603 7.03464 10.3101 6.89273 9.96753C6.75081 9.62492 6.71368 9.24792 6.78603 8.8842C6.85837 8.52049 7.03695 8.1864 7.29917 7.92417C7.5614 7.66195 7.89549 7.48337 8.2592 7.41103C8.62292 7.33868 8.99992 7.37581 9.34253 7.51772C9.68514 7.65964 9.97798 7.89996 10.184 8.20831C10.39 8.51665 10.5 8.87916 10.5 9.25C10.5 9.74728 10.3025 10.2242 9.95082 10.5758C9.59919 10.9275 9.12228 11.125 8.625 11.125ZM8.625 0.625C6.33827 0.627482 4.14591 1.53698 2.52894 3.15394C0.911981 4.77091 0.00248153 6.96327 0 9.25C0 16.5025 7.65375 21.9437 7.98 22.1716C8.16907 22.3039 8.39424 22.3748 8.625 22.3748C8.85576 22.3748 9.08094 22.3039 9.27 22.1716C10.7196 21.1032 12.0459 19.8769 13.2244 18.5153C15.8578 15.4909 17.25 12.2847 17.25 9.25C17.2475 6.96327 16.338 4.77091 14.7211 3.15394C13.1041 1.53698 10.9117 0.627482 8.625 0.625ZM11.5594 17.0041C10.6655 18.0308 9.68364 18.9776 8.625 19.8334C7.56636 18.9776 6.5845 18.0308 5.69062 17.0041C4.125 15.1909 2.25 12.3728 2.25 9.25C2.25 7.55924 2.92165 5.93774 4.11719 4.74219C5.31274 3.54665 6.93425 2.875 8.625 2.875C10.3158 2.875 11.9373 3.54665 13.1328 4.74219C14.3284 5.93774 15 7.55924 15 9.25C15 12.3728 13.125 15.1909 11.5594 17.0041Z" fill="black"/>
    </svg>

    {{ address }}
  </p>

  <Teleport to="body">
    <MapPopup
        @closeMap="closeMap"
        :showMap="showMap"
        :imageSrc="map_image"
    />
  </Teleport>
</template>

<style scoped>
.location-button {
  display: flex;
  align-items: center;
  gap: 5px;

  margin-bottom: 30px;
  line-height: 1;

  text-decoration: underline;
  cursor: pointer;
}

.location-button:hover {
  text-decoration: none;
}

@media (max-width: 1024px) {
  .location-button {
    margin-bottom: 15px;
  }
}
</style>
