import "./assets/main.css";

import { createApp } from "vue";
import { createMetaManager, defaultConfig } from "vue-meta";
import App from "./App.vue";
import router from "./router";
import i18n from "@/i18n";
import VueSocialSharing from "vue-social-sharing";
import VueClipboard from "vue3-clipboard";
import {GoogleMap} from "vue3-google-map";
import VueTheMask from "vue-the-mask";
import store from "@/store/store";
import PerfectScrollbar from 'vue3-perfect-scrollbar'
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'
import {createGtm} from "vue-gtm";
// import {createGtm} from "vue-gtm";
// import VueGtag from "vue-gtag";

const app = createApp(App);

app.use(router).use(VueSocialSharing);
app.use(i18n);
app.use(GoogleMap);
app.use(createMetaManager(false, {
    ...defaultConfig,
    meta: { tag: 'meta', nameless: true },
}));

app.use(VueClipboard, {
    autoSetContainer: true,
    appendToBody: true,
});
app.use(VueTheMask);
app.use(store);
app.use(PerfectScrollbar);


// app.use(VueGtag, {
//     config: {
//         id: "G-7MDHGBQCMJ",
//     }
// })


app.use( createGtm({
    id: 'GTM-PJG5KFZ',
    enabled: true, // Включити відстеження
    debug: false, // Вимкнути режим налагодження
    vueRouter: router, // Передати змінну Vue Router
    defer: true, // Встановити атрибут defer для завантаження скрипта GTM
    trackOnNextTick: false, // Вимкнути відстеження подій на наступному циклі Vue
    compatibility: 'auto', // Автоматично визначити сумісність з іншими плагінами
    scriptId: 'gtm-script', // Ідентифікатор скрипта GTM
    noscriptId: 'gtm-noscript' // Ідентифікатор тегу noscript GTM
}))

app.mount('#app')
