<script setup>
import {ref} from "vue";
import {useI18n} from "vue-i18n";

defineProps(['show']);

const emit = defineEmits(['close']);

const { t } = useI18n();

const isSuccess = ref(false);
const email = ref('');

const handleClose = () => {
  emit('close');

  isSuccess.value = false;
  email.value = '';
};

const handleSubmit = () => {
  isSuccess.value = true;
};
</script>

<template>
  <Transition v-if="show" name="modal">
    <div class="overlay" @click="handleClose">
      <div
        :class="{ 'subscription-popup--success': isSuccess }"
        class="subscription-popup popup"
        @click.stop
      >
        <button
          @click="handleClose"
          class="modal__button-close subscription-popup__close"
        >
          <img
            src="@/assets/images/close.svg"
            alt="close form"
            class="modal__icon-close"
          >
        </button>

        <div class="subscription-popup__content">
          <div
           :class="{ 'subscription-popup__wrapper--success': isSuccess }"
            class="subscription-popup__wrapper"
          >
            <img
              src="@/assets/images/Group949.png"
              alt="envelope"
              class="subscription-popup__icon"
            >
            <p
              v-if="!isSuccess"
              class="subscription-popup__title section__link"
            >
              {{ t("findOutNews") }}
            </p>

            <p
              v-else
              class="section__text subscription-popup__text"
            >
              {{ t("subscriptionSuccess") }}
            </p>
          </div>

          <form
            @submit.prevent="handleSubmit"
            v-if="!isSuccess"
            class="contact-form"
          >
            <input
              v-model="email"
              type="email"
              placeholder="Email"
              required
              class="contact-form__field subscription-popup__input"
            >
            <button class="contact-form__button subscription-popup__button">
              {{ t("signUp") }}
            </button>
          </form>
        </div>
      </div>
    </div>
  </Transition>
</template>

<style scoped>
.subscription-popup {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  width: 700px;
  height: 276px;
  padding: 60px;

  border-radius: 30px;
  background-color: var(--blago-white);
}

.subscription-popup--success {
  flex-direction: row;
  padding: 0 125px;
}

.subscription-popup__wrapper {
  display: flex;
  gap: 20px;
  align-items: center;

  margin-bottom: 45px;
}

.subscription-popup__wrapper--success {
  margin-bottom: 0;
}

.subscription-popup__title {
  text-align: center;
}

.subscription-popup__text {
  color: var(--black);
}

.subscription-popup__input {
  border: 1px solid #E0E0E0;
  border-radius: 22px 0 0 22px;
}

@media (max-width: 768px) {
  .subscription-popup {
    width: 370px;
    height: 450px;
    padding: 100px 25px 90px;
  }

  .subscription-popup__wrapper {
    flex-direction: column;

    margin-bottom: 20px;
  }

  .subscription-popup__input {
    border-radius: 22px;
  }

  .subscription-popup__text {
    text-align: center;
  }
}
</style>
