<script setup>
import {RouterView} from 'vue-router';
import {useMeta} from "vue-meta";
import {params} from "@/config";

useMeta({
  title: '',
  meta: [
    {'name' : 'format-detection', 'content' : 'telephone=no'},
    {'name' : 'robots', 'content' : 'index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1'},
    {'property' : 'og:type', 'content' : 'website'},
    // {'property' : 'og:site_name', 'content' : 'blago – створюємо квартири зручні для життя!'},
    {'http-equiv' : 'x-ua-compatible', 'content' : 'ie=edge'},
  ]
})
</script>

<template>
  <metainfo>
    <template v-slot:title="{ content }">
      {{ params.title(content) }}
    </template>
  </metainfo>

  <RouterView />
</template>

<style scoped>
</style>
