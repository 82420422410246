import { createStore } from "vuex";

export default createStore({
    state: {
        comparisonPlanningsIds: JSON.parse(localStorage.getItem('comparisonPlanningsIds')) || [],
        lastReadProjectsIds: JSON.parse(localStorage.getItem('lastReadProjectsIds')) || [],
    },
    mutations: {
        togglePlanningInComparison(state, planning) {
            if (state.comparisonPlanningsIds.find(item => +item === +planning['planning_id'] || +item === +planning['id'])) {
                state.comparisonPlanningsIds = state.comparisonPlanningsIds.filter(item => +planning['planning_id'] !== +item && +item !== +planning['id']);
                localStorage.setItem('comparisonPlanningsIds', JSON.stringify(state.comparisonPlanningsIds));
            } else {
                if (planning['planning_id']) {
                    state.comparisonPlanningsIds.push(planning['planning_id']);
                } else {
                    state.comparisonPlanningsIds.push(planning['id']);
                }
                localStorage.setItem('comparisonPlanningsIds', JSON.stringify(state.comparisonPlanningsIds));
            }
        },
        deleteAllPlanning(state) {
            state.comparisonPlanningsIds = [];
            localStorage.setItem('comparisonPlanningsIds', JSON.stringify(state.comparisonPlanningsIds));
        },
        addLastReadProjects(state, id) {
            if (state.lastReadProjectsIds.find(item => +item === +id)) {
                state.lastReadProjectsIds = state.lastReadProjectsIds.filter(item => +id !== +item);
                state.lastReadProjectsIds.unshift(id);
                localStorage.setItem('lastReadProjectsIds', JSON.stringify(state.lastReadProjectsIds));
            } else {
                state.lastReadProjectsIds.unshift(id);
                localStorage.setItem('lastReadProjectsIds', JSON.stringify(state.lastReadProjectsIds));
            }
        },
    },
    actions: {
        togglePlanningInComparison({ commit }, planning) {
            commit('togglePlanningInComparison', planning);
        },
        deleteAllPlanning({ commit }) {
            commit('deleteAllPlanning');
        },
        addLastReadProjects({ commit }, project) {
            commit('addLastReadProjects', project);
        },
    },
    getters: {
        comparisonPlanningsCount: state => state.comparisonPlanningsIds.length,
    },
});
