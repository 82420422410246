<script setup>
import {RouterLink, useRoute} from 'vue-router'
import {computed, onMounted, ref} from "vue";
import {useI18n} from "vue-i18n";
import i18n from "@/i18n";
import Dropdown from "@/components/DropdownMenu.vue";

const { t } = useI18n();
const activePage = ref('');
const route = useRoute();

const isActiveCompanies = ref(false);

const locale = computed(() => {
  return i18n.global.locale.value === 'uk' ? '' : i18n.global.locale.value;
});

onMounted(() => {
  activePage.value = route.name;

  isActiveCompanies.value = activePage.value === 'service' || activePage.value === 'family'
});
</script>

<template>
  <nav class="nav">
    <RouterLink
      :to="{ name: 'about', params: { locale } }"
      :class="{ 'nav__link--active': activePage === 'about' }"
      class="section__link nav__link"
    >
      {{ t("aboutUs") }}
    </RouterLink>

    <Dropdown
      :is-active="isActiveCompanies"
    >
      {{ t("company") }}
    </Dropdown>

    <RouterLink
      :to="{ name: 'projects', params: { locale } }"
      :class="{ 'nav__link--active': activePage === 'projects' }"
      class="section__link nav__link"
    >
      {{ t("projectsTitle") }}
    </RouterLink>

    <RouterLink
      :to="{ name: 'apartments', params: { locale } }"
      :class="{ 'nav__link--active': activePage === 'apartments' }"
      class="section__link nav__link"
    >
      {{ t("readyForRepair") }}
    </RouterLink>

    <RouterLink
      :to="{ name: 'filter', params: { locale } }"
      :class="{ 'nav__link--active': activePage === 'filter' }"
      class="section__link nav__link"
    >
      {{ t("apartmentsFilter") }}
    </RouterLink>

    <RouterLink
      :to="{ name: 'news', params: { locale } }"
      :class="{ 'nav__link--active': activePage === 'news' }"
      class="section__link nav__link"
    >
      {{ t("news") }}
    </RouterLink>

<!--    <RouterLink-->
<!--      :to="{ name: 'blog', params: { locale } }"-->
<!--      :class="{ 'nav__link&#45;&#45;active': activePage === 'blog' }"-->
<!--      class="section__link nav__link"-->
<!--    >-->
<!--      {{ t("blog") }}-->
<!--    </RouterLink>-->

    <RouterLink
      :to="{ name: 'contacts', params: { locale } }"
      :class="{ 'nav__link--active': activePage === 'contacts' }"
      class="section__link nav__link"
    >
      {{ t("contactsTitle") }}
    </RouterLink>
  </nav>
</template>

<style scoped>
  .nav {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 5px;
  }
  
  @media (max-width: 1024px) {
    .nav {
      display: none;
    }
  }
</style>
