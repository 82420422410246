<script setup>
import {computed, ref} from "vue";
import {useI18n} from "vue-i18n";
import axios from "axios";
import {params} from "@/config";

const { t } = useI18n();
const phoneNumber = ref('');
const emit = defineEmits(['submitForm']);

const placeholder = computed(() => t("phoneNumber"));
const handleSubmit = () => {
 axios.post(params.api_url+'/system/sendMessageToBot',{
   form_name:'Бажаєте дізнатись ціну?',
   phone_number:phoneNumber.value,
   utm:window.location.search

 })

  emit('submitForm');
};
</script>

<template>
  <form
    @submit.prevent="handleSubmit"
    class="contact-form"

  >
    <input
      type="tel"
      :placeholder="placeholder"
      required
      v-model="phoneNumber"
      v-mask="'+38 (0##) ###-##-##'"
      class="contact-form__field"
    >
    <button class="contact-form__button" type="submit">
      {{ t("find_out") }}
    </button>
  </form>
</template>

<style scoped>
</style>
