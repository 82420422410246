<script setup>
import {useI18n} from "vue-i18n";
import {onMounted, onUnmounted, ref} from "vue";

const { t } = useI18n();

const isFlipped = ref(false);
let intervalId = null;

const flipCard = () => {
  isFlipped.value = !isFlipped.value;
};

const startFlipping = () => {
  if (!intervalId) {
    intervalId = setInterval(flipCard, 3000);
  }
};

const stopFlipping = () => {
  clearInterval(intervalId);
  intervalId = null;
};

const showText = () => {
  if (isFlipped.value) {
    flipCard();
  }
  stopFlipping();
};

onMounted(() => {
  startFlipping();
});

onUnmounted(() => {
  stopFlipping();
});
</script>

<template>
  <div class="call-button__wrapper tl-call-catcher">
    <div class="call-button__message">
      {{ t("callbackMessage") }}
    </div>

    <div class="flip-container" @mouseover="showText" @mouseout="startFlipping">
      <div class="flipper" :class="{'flipped': isFlipped}">
        <button class="call-button__front call-button">
          {{ t("orderCall") }}
        </button>
        <button class="call-button__back call-button">
          <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_6383_32951)">
              <path d="M12.085 19.3825C14.605 24.335 18.665 28.3775 23.6175 30.915L27.4675 27.065C27.94 26.5925 28.64 26.435 29.2525 26.645C31.2125 27.2925 33.33 27.6425 35.5 27.6425C36.4625 27.6425 37.25 28.43 37.25 29.3925V35.5C37.25 36.4625 36.4625 37.25 35.5 37.25C19.0675 37.25 5.75 23.9325 5.75 7.5C5.75 6.5375 6.5375 5.75 7.5 5.75H13.625C14.5875 5.75 15.375 6.5375 15.375 7.5C15.375 9.6875 15.725 11.7875 16.3725 13.7475C16.565 14.36 16.425 15.0425 15.935 15.5325L12.085 19.3825Z" fill="black"/>
            </g>
            <defs>
              <clipPath id="clip0_6383_32951">
                <rect width="42" height="42" fill="white" transform="translate(0.5 0.5)"/>
              </clipPath>
            </defs>
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.call-button__wrapper {
  position: fixed;
  right: 40px;
  bottom: 50px;

  display: flex;
  flex-direction: column;
  align-items: flex-end;

  width: 80px;
  height: 80px;

  z-index: 3;
}

.call-button__message {
  display: none;
  justify-content: center;
  align-items: flex-start;

  position: absolute;
  top: 0;
  right: 50%;
  transform: translate(8%, -100%);

  width: 217px;
  height: 47px;
  padding-top: 10px;

  font-size: 12px;
  font-weight: 400;
  background-image: url("@/assets/images/callMessageBackground.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.call-button {
  width: 80px;
  height: 80px;

  font-size: 11px;
  font-weight: 800;
  line-height: 105%;
  border: 2px solid var(--blago-bright-aquamarine);
  border-radius: 50%;
  color: var(--blago-black);
  background-color: var(--blago-bright-aquamarine);
  cursor: pointer;

  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;

  transition: all 0.3s;
}

.flip-container {
  perspective: 1000px;
  width: 100%;
  height: 100%;
}

.call-button__wrapper:hover .call-button__message {
  display: flex;
}

.flipper {
  position: relative;
  width: 100%;
  height: 100%;
  transition: 0.6s;
  transform-style: preserve-3d;
}

.flipped {
  transform: rotateY(180deg);
}

.call-button__front, .call-button__back {
  background-color: var(--blago-bright-aquamarine);
}

.call-button__back {
  transform: rotateY(180deg);
}

@media screen and (max-width: 768px) {
  .call-button__wrapper {
    display: none;

    position: fixed;
    right: 8px;
    bottom: 80px;
  }

  .call-button__message {
    display: none;
  }
}
</style>
