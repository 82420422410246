<script setup>
import {computed, ref, watchEffect} from "vue";
import axios from "axios";
import {params} from "@/config";
import i18n from "@/i18n";
import {useMeta} from "vue-meta";
import Header from "@/components/Header.vue";
import Advantages from "@/components/pages/Apartments/Components/Advantages.vue";
import Planing from "@/components/Planing.vue";
import ReadyProjects from "@/components/pages/Apartments/Components/ReadyProjects.vue";
import Contacts from "@/components/Contacts.vue";
import Footer from "@/components/Footer.vue";
import Planning from "@/components/pages/Apartments/Components/Planning.vue";
import ButtonsForMobile from "@/components/ButtonsForMobile.vue";

const lang = computed(() => {
  return i18n.global.locale.value;
});

const pageContent = ref({});
const metaInfo = useMeta({og: {}});

watchEffect(() => {
  getData();
})

async function getData() {
  await axios.get(`${params.api_url}/pages/specialOffers`, { params: {lang: lang.value }})
    .then(({ data }) => {
      pageContent.value = data;

      const titleElement = document.createElement('div');
      titleElement.innerHTML = data.meta.title;

      metaInfo.meta.title = titleElement.innerText;
      metaInfo.meta.description = data.meta.description;
      metaInfo.meta.og.title = titleElement.innerText;
      metaInfo.meta.og.description = data.meta.description;
    })
}
</script>

<template>
  <Header :slides="pageContent.slider" />

  <main>
    <ButtonsForMobile
      v-if="pageContent.meta"
      :nav_button="pageContent.meta['navigation_bar']"
    />

    <Advantages :advantages="pageContent.advantages" />

    <Planning
      v-if="pageContent.plannings"
      :projects="pageContent.plannings"
    />

    <ReadyProjects
      v-if="pageContent['projects_for_renovation']"
      :projects="pageContent['projects_for_renovation']"
    />

    <Contacts />
  </main>

  <Footer />
</template>

<style scoped></style>
