<script setup>
import {onMounted, onUnmounted, ref} from "vue";
import {useI18n} from "vue-i18n";

defineProps(['nav_button']);

const { t } = useI18n();

const isVisible = ref(false);

const handleScroll = () => {
    isVisible.value = window.scrollY > 0;
};

onMounted(() => {
  window.addEventListener('scroll', handleScroll);
});

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll);
});
</script>

<template>
  <div :class="{'buttons-for-mobile--visible': isVisible}" class="buttons-for-mobile">
    <a
      v-if="nav_button && nav_button.text && nav_button.link"
      :href="nav_button.link"
      :style="{'color': nav_button.color_text, 'background-color': nav_button.color_background}"
      class="buttons-for-mobile__button-new"
    >
      {{ nav_button.text }}
    </a>

    <div class="buttons-for-mobile__button-new buttons-for-mobile__button-call tl-call-catcher">
      {{ t("orderCall") }}
    </div>
  </div>
</template>

<style scoped>
.buttons-for-mobile {
  display: none;
}

@media (max-width: 768px) {
  .buttons-for-mobile {
    position: fixed;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 20;

    display: flex;
    gap: 8px;
    padding: 16px 8px;

    transform: translateY(100%);
    transition: transform 0.5s ease-in-out;
  }

  .buttons-for-mobile--visible {
    transform: translateY(0);
  }

  .buttons-for-mobile__button-new {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    padding: 16px 20px;

    font-size: 16px;
    font-weight: 800;
    line-height: 145%;
    text-align: center;
    text-decoration: none;

    border-radius: 80px;

    box-shadow: 0 2px 4px 0 #2431210D,
      0 7px 7px 0 #2431210A,
      0 27px 11px 0 #24312103,
      0 42px 12px 0 #24312100,
      0 2px 4px 0 #2431210D;

  }

  .buttons-for-mobile__button-call {
    background-color: var(--blago-bright-aquamarine);
    border-color: var(--blago-bright-aquamarine);

    cursor: pointer;
  }

  .buttons-for-mobile__button {
    display: inline-block;
    width: 100%;
    padding: 12px 0;

    font-size: 16px;
    font-weight: 800;
    line-height: 145%;
    color: var(--blago-black);
    text-align: center;
    text-decoration: none;

    background-color: var(--blago-green);
    border: none;
    border-top: 2px solid var(--blago-black);
    border-bottom: 2px solid var(--blago-black);
    cursor: pointer;
    opacity: 0.95;
  }

  .buttons-for-mobile__button:not(:last-child) {
    border-right: 2px solid var(--blago-black);
  }

  .buttons-for-mobile__button--red {
    background-color: #F0003E;
  }
}
</style>
