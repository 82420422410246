<script setup>
import {useI18n} from "vue-i18n";
import ProjectsSlider from "@/components/ProjectsSlider.vue";

const { t } = useI18n();

defineProps(['projects']);
</script>

<template>
  <section class="section ready__projects">
    <div>
      <h2 class="section__title ready-projects__title">
        {{ t("readyProjects") }}
      </h2>

      <ProjectsSlider :projects="projects" />
    </div>
  </section>
</template>

<style scoped>
.ready__projects {
  padding-bottom: 80px;

  border-bottom: 1px solid var(--alternative-brown);
}

@media (max-width: 1024px) {
  .ready__projects {
    padding: 0 40px 40px;
  }

  .ready-projects__title {
    margin-bottom: 20px;

    text-align: center;
  }
}

@media (max-width: 768px) {
  .ready__projects {
    padding: 0 20px 40px;
  }
}
</style>
