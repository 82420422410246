<script setup>
import {computed, onMounted, ref, watch} from "vue";
import {useStore} from "vuex";
import axios from "axios";
import i18n from "@/i18n";
import {params} from "@/config";
import Header from "@/components/Header2.vue";
import Footer from "@/components/Footer.vue";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import ProjectsBlock from "@/components/ProjectsBlock.vue";
import PlanningList from "@/components/pages/PlanningComparison/Components/PlanningList.vue";

const store = useStore();
const lastReadProjectsIds = computed(() => store.state.lastReadProjectsIds);
const projects = ref([]);

const getProjects = () => {axios.get(`${params.api_url}/projects/getLastReadProjects`, { params: { projects: lastReadProjectsIds.value.join('|'), lang: i18n.global.locale.value } })
    .then(({ data }) => {
      projects.value = data;
    })
};


onMounted(() => {
  if (lastReadProjectsIds.value.length) {
    getProjects();
  }
})

watch(i18n.global.locale, () => {
  if (lastReadProjectsIds.value.length) {
    getProjects();
  }
})
</script>

<template>
  <Header />

  <main>
    <Breadcrumbs />
    <PlanningList />
    <ProjectsBlock v-if="projects.length" :projects="projects" />
  </main>

  <Footer />
</template>

<style scoped></style>
