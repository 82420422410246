<script setup>
defineProps(['show', 'url']);

const emit = defineEmits(['close']);

const handleClose = () => {
  emit('close');
};
</script>

<template>
  <Transition v-if="show" name="modal">
    <div class="overlay" @click="handleClose">
      <div class="aero-tour popup" @click.stop>
        <button
          @click="handleClose"
          class="modal__button-close aero-tour__close"
        >
          <img
            src="@/assets/images/close-for-aero.svg"
            alt="close aero tour"
            class="modal__icon-close aero-tour__close__icon"
          >
        </button>

        <iframe class="aero-tour__content" :src="url"></iframe>
      </div>
    </div>
  </Transition>
</template>

<style scoped>
.aero-tour {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  //height: 780px;
  height: 90%;
  width: 90%;

  border-radius: 30px;
  background-color: var(--blago-white);
}

.aero-tour__content {
  width: 100%;
  height: 100%;

  border: none;
  border-radius: 30px;
}

.aero-tour__close {
  position: absolute;
  top: -10px;
  right: -10px;

  width: 36px;
  height: 36px;
}

.aero-tour__close__icon {
  width: 100%;
  height: 100%;
}

@media (max-width: 1024px) {
  .aero-tour {
    height: 480px;
  }
}

@media (max-width: 768px) {
  .aero-tour {
    max-height: 280px;
  }
}
</style>
