<script setup>
import {computed, onMounted, onUnmounted, ref} from "vue";
import {useI18n} from "vue-i18n";
import i18n from "@/i18n";
import eOselia from '/src/assets/images/ProjectsPage/eOselia.png'
import eRecovery from '/src/assets/images/ProjectsPage/eRecovery.png'
import eOseliaMobile from '/src/assets/images/ProjectsPage/eOseliaMobile.png'
import eRecoveryMobile from '/src/assets/images/ProjectsPage/eRecoveryMobile.png'


  const props = defineProps(['apartment']);

  const { t } = useI18n();

  const formattedPrice = computed(() => {
    return props.apartment.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  });

const locale = computed(() => {
  return i18n.global.locale.value === 'uk' ? '' : i18n.global.locale.value;
});

const currentIndex = ref(0);
const windowWidth = ref(window.innerWidth);
const imagesUrl = ref(windowWidth.value > 768
    ? [eOselia, eRecovery]
    : [eOseliaMobile, eRecoveryMobile]);
const currentImg = computed(() => imagesUrl.value[currentIndex.value]);

const handleResize = () => {
  windowWidth.value = window.innerWidth;

  imagesUrl.value = windowWidth.value > 768
      ? [eOselia, eRecovery]
      : [eOseliaMobile, eRecoveryMobile];
};

onMounted(() => {
  setInterval(() => {
    currentIndex.value = (currentIndex.value + 1) % imagesUrl.value.length;
  }, 5000);

  window.addEventListener('resize', handleResize);
});

onUnmounted(() => {
  window.removeEventListener('resize', handleResize);
});
</script>

<template>
  <a
    :href="apartment['project_is_have_site'] ? `${apartment['project_site_link']}` : locale ? `/${locale}/projects/${apartment.slug}` : `/projects/${apartment.slug}`"
    target="_blank"
    :style="{'background': `linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%), url(${apartment.images || apartment.image}) center/cover, lightgray 0px -153.417px / 100% 155.174% no-repeat`}"
    class="apartment-card"
  >
    <div>
      <div class="apartment-card__wrapper">
        <div class="apartment-card__tags-wrapper">
          <p
              v-for="tag in apartment.tags"
              :key="tag.text"
              :style="`background-color: ${tag['tag_color']}`"
              class="apartment-card__tag"
          >
            {{ tag.text }}
          </p>

          <p
              v-for="classItem in apartment['object_class']"
              :key="classItem"
              class="apartment-card__object-class"
          >
            {{ t(classItem) }}
          </p>
        </div>

        <img width="208" height="24" v-if="apartment.eoselia && apartment.erecovery" :src="currentImg" class="apartment-card__eoselia" alt="eoselia" />
        <img width="208" height="24" v-if="apartment.eoselia && !apartment.erecovery" :src="windowWidth > 768 ? eOselia : eOseliaMobile" class="apartment-card__eoselia"  alt="eoselia"/>
        <img width="208" height="24" v-if="!apartment.eoselia && apartment.erecovery" :src="windowWidth > 768 ?eRecovery : eRecoveryMobile" class="apartment-card__eoselia" alt="eRecovery"/>
      </div>

      <p v-if="apartment.price !== 0" class="apartment-card__price">
        {{ `${t("from")} ${formattedPrice} ${apartment.currency === 'uah' ? t("measure") : t("measureUsd")}` }}
      </p>
    </div>

    <div class="apartment-card__title-wrapper">
      <h3 class="apartment-card__title">
        {{apartment.title}}
      </h3>

      <p class="apartment-card__street">
        {{apartment.address}}
      </p>
    </div>
  </a>
</template>

<style scoped>
</style>
