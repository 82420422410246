<script setup>
import {computed, onUnmounted} from "vue";
import i18n from "@/i18n";

defineProps(['showMap', 'imageSrc']);

const emit = defineEmits(['closeMap']);

const handleClose = () => {
  emit('closeMap');
};

onUnmounted(() => {
  document.body.classList.remove('body__modal-open');
})

const locale = computed(() => {
  return i18n.global.locale.value === 'uk' ? '' : i18n.global.locale.value;
});
</script>

<template>
  <Transition v-if="showMap" name="modal">
    <div class="overlay" @click="handleClose">
      <div class="apartment-popup" @click.stop>
        <button
          @click="handleClose"
          class="modal__button-close"
        >
          <img
            src="@/assets/images/close.svg"
            alt="close form"
            class="modal__icon-close"
          >
        </button>

        <div class="apartment-popup__image-wrapper">
          <img
            :src="imageSrc"
            alt="map"
            class="apartment-popup__image"
          >
        </div>
      </div>
    </div>
  </Transition>
</template>

<style scoped>
.apartment-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 3;

  transform: translate(-50%, -50%);

  display: flex;
  gap: 30px;

  max-width: 90vw;
  padding: 30px;

  border-radius: 30px;
  background-color: var(--blago-white);
}

.apartment-popup__image-wrapper {
  width: 100%;
}

.apartment-popup__image {
  width: 100%;
  height: 100%;

  object-fit: cover;
  object-position: center;
}

@media (max-width: 1024px) {
  .apartment-popup {
    flex-direction: column;
    gap: 20px;

    width: 100%;
    max-height: 100%;
    overflow-y: auto;
    padding: 35px 20px;
  }
}

@media (max-width: 768px) {
  .apartment-popup {
    max-width: 100vw;
  }
}
</style>
