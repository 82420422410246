import { createRouter, createWebHistory} from "vue-router";
import i18n from "@/i18n";
import {defineAsyncComponent} from "vue";
import PlanningComparison from "@/components/pages/PlanningComparison/PlanningComparison.vue";
import SpecialOffers from "@/components/pages/Apartments/SpecialOffers.vue";

const Home = defineAsyncComponent(() => import('@/components/pages/Home/Home.vue'))
const About = defineAsyncComponent(() => import('@/components/pages/About/About.vue'))
const Projects = defineAsyncComponent(() => import('@/components/pages/Projects/Projects.vue'))
const Apartments = defineAsyncComponent(() => import('@/components/pages/Apartments/Apartments.vue'))
const Filter = defineAsyncComponent(() => import('@/components/pages/Filter/Filter.vue'))
const News = defineAsyncComponent(() => import('@/components/pages/News/News.vue'))
const Contacts = defineAsyncComponent(() => import('@/components/pages/Contacts/Contacts.vue'))
const Service = defineAsyncComponent(() => import('@/components/pages/Service/Service.vue'))
const BlagoFamily = defineAsyncComponent(() => import('@/components/pages/BlagoFamily/BlagoFamily.vue'))
const Project = defineAsyncComponent(() => import('@/components/pages/Project/Project.vue'))
const NewsItem = defineAsyncComponent(() => import('@/components/pages/NewsItem/NewsItem.vue'))
const Blog = defineAsyncComponent(() => import('@/components/pages/Blog/Blog.vue'))
const MapPage = defineAsyncComponent(() => import('@/components/pages/Map/MapPage.vue'))
const Article = defineAsyncComponent(() => import('@/components/pages/Article/Article.vue'))
const ErrorPage = defineAsyncComponent(() => import('@/components/pages/ErrorPage/ErrorPage.vue'))
const PrivacyPolicy = defineAsyncComponent(() => import('@/components/pages/PrivacyPolicy/PrivacyPolicy.vue'))
const AccountDeletionInstruction = defineAsyncComponent(() => import('@/components/pages/AccountDeletionInstruction/AccountDeletionInstruction.vue'))
const PromotionalLanding = defineAsyncComponent(() => import('@/components/pages/PromotionalLanding/PromotionalLanding.vue'))
const AppPage = defineAsyncComponent(() => import('@/components/pages/AppPage/AppPage.vue'))


const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: '/:locale(en)?',
            name: 'home',
            component: Home,
            meta: {
                breadcrumb: {
                    text: 'home',
                    to: '/',
                }
            },
        },
        {
            path: '/:locale(en)?/pro-nas',
            name: 'about',
            component: About,
        },
        {
            path: '/:locale(en)?/blago-servis',
            name: 'service',
            component: Service,
        },
        {
            path: '/:locale(en)?/kartka-blago/:id?',
            name: 'family',
            component: BlagoFamily,
        },
        {
            path: '/:locale(en)?/projects',
            name: 'projects',
            component: Projects,
            meta: {
                breadcrumb: {
                    text: 'projectsTitle',
                    to: '/projects'
                }
            },
        },
        {
            path: '/:locale(en)?/projects/:projectName',
            name: 'project',
            component: Project,
        },
        {
            path: '/:locale(en)?/kvartyry-gotovi-do-remontu',
            name: 'apartments',
            component: Apartments,
        },
        {
            path: '/:locale(en)?/special-offers',
            name: 'SpecialOffers',
            component: SpecialOffers,
        },
        {
            path: '/:locale?/plannings/:id?',
            name: 'filter',
            component: Filter,
            meta: {
                breadcrumb: {
                    text: 'apartmentsFilter',
                    to: '/plannings',
                }
            },
            children: [
                {
                    path: '/:locale(en)?/plannings/projects/:projects',
                    component: Filter,
                    meta: {
                        breadcrumb: {
                            text: 'apartmentsFilter',
                            to: '/plannings',
                        }
                    },
                },
                {
                    path: '/:locale(en)?/plannings/rooms_count/:rooms_count',
                    component: Filter,
                    meta: {
                        breadcrumb: {
                            text: 'apartmentsFilter',
                            to: '/plannings',
                        }
                    },
                },
                {
                    path: '/:locale(en)?/plannings/price/:price',
                    component: Filter,
                    meta: {
                        breadcrumb: {
                            text: 'apartmentsFilter',
                            to: '/plannings',
                        }
                    },
                },
                {
                    path: '/:locale(en)?/plannings/square/:square',
                    component: Filter,
                    meta: {
                        breadcrumb: {
                            text: 'apartmentsFilter',
                            to: '/plannings',
                        }
                    },
                },
                {
                    path: '/:locale(en)?/plannings/eoselia/:eoselia',
                    component: Filter,
                    meta: {
                        breadcrumb: {
                            text: 'apartmentsFilter',
                            to: '/plannings',
                        }
                    },
                },
                {
                    path: '/:locale(en)?/plannings/complete_percent/:complete_percent',
                    component: Filter,
                    meta: {
                        breadcrumb: {
                            text: 'apartmentsFilter',
                            to: '/plannings',
                        }
                    },
                },
                {
                    path: '/:locale(en)?/plannings/area/:area',
                    component: Filter,
                    meta: {
                        breadcrumb: {
                            text: 'apartmentsFilter',
                            to: '/plannings',
                        }
                    },
                },
                {
                    path: '/:locale(en)?/plannings/feature/:feature',
                    component: Filter,
                    meta: {
                        breadcrumb: {
                            text: 'apartmentsFilter',
                            to: '/plannings',
                        }
                    },
                },
            ]
        },
        {
            path: '/:locale(en)?/comparison',
            name: 'comparison',
            component: PlanningComparison,
            meta: {
                breadcrumb: {
                    text: 'comparison',
                    to: '/comparison'
                }
            },
        },
        {
            path: '/:locale(en)?/comparison/:projects(.*)',
            name: 'comparison-dynamic',
            component: PlanningComparison,
            meta: {
                breadcrumb: {
                    text: 'comparison',
                    to: '/comparison'
                }
            },
        },
        {
            path: '/:locale(en)?/blog',
            name: 'blog',
            component: Blog,
            meta: {
                breadcrumb: {
                    text: 'blog',
                    to: '/blog'
                }
            },
        },
        {
            path: '/:locale(en)?/blog/:article',
            name: 'article',
            component: Article,
            meta: {
                breadcrumb: [{
                    text: 'blog',
                    to: '/blog'
                },
                    {
                        text: '',
                        to: '/blog/:article'
                    }
                ],
            },
        },
        {
            path: '/:locale(en)?/news',
            name: 'news',
            component: News,
            meta: {
                breadcrumb: {
                    text: 'news',
                    to: '/news'
                }
            },
        },
        {
            path: '/:locale(en)?/news/page/:page',
            name: 'newsWithPage',
            component: News,
            meta: {
                breadcrumb: {
                    text: 'news',
                    to: '/news'
                }
            },
        },
        {
            path: '/:locale(en)?/news/:newsItem',
            name: 'newsItem',
            component: NewsItem,
            meta: {
                breadcrumb: [{
                    text: 'news',
                    to: '/news'
                },
                {
                    text: '',
                    to: '/news/:newsItem'
                }
                ],
            },
        },
        {
            path: '/:locale(en)?/contacts',
            name: 'contacts',
            component: Contacts,
            meta: {
                breadcrumb: {
                    text: 'contactsTitle',
                    to: '/contacts'
                }
            },
        },
        {
            path: '/:locale(en)?/privacy-policy',
            name: 'privacyPolicy',
            component: PrivacyPolicy,
            meta: {
                breadcrumb: {
                    text: 'privacyPolicy',
                    to: '/privacy-policy'
                }
            },
        },
        {
            path: '/:locale(en)?/account-deletion-instruction',
            name: 'accountDeletionInstruction',
            component: AccountDeletionInstruction,
            meta: {
                breadcrumb: {
                    text: 'accountDeletionInstruction',
                    to: '/account-deletion-instruction'
                }
            },
        },
        {
            path: '/:locale(en)?/map',
            name: 'map',
            component: MapPage,
        },
        {
            path: '/:pathMatch(.*)*',
            component: ErrorPage,
        },
        {
            path: '/:locale(en)?/promotional-landing',
            name: 'promotional-landing',
            component: PromotionalLanding,
        },
        {
            path: '/:locale(en)?/app',
            name: 'app-page',
            component: AppPage,
        },
    ],
    scrollBehavior(to, from, savedPosition) {
        if (!to.path.includes('/plannings') && !to.path.includes('/kartka-blago')) {
            return { top: 0, behavior: 'smooth' };
        } else {
            return { behavior: 'smooth' };
        }
    },
});


const breadcrumbs = [];

router.beforeEach((to, from, next) => {
    breadcrumbs.length = 0;

    breadcrumbs.push({ text: 'home', to: `/` });

    to.matched.forEach(route => {
        if (Array.isArray(route.meta.breadcrumb)) {
            for (const crumb of route.meta.breadcrumb) {
                breadcrumbs.push(crumb);
            }
        } else {
            breadcrumbs.push(...to.matched.map(route => route.meta.breadcrumb));
        }
    });

    const locale= to.params.locale;

    if (['en', 'uk'].includes(locale)) {
        i18n.global.locale.value = locale;
    }

    next();
});

export default router
export { breadcrumbs }
