export function openPhonetPopup(){
    let block =  document.getElementById('phonetOpenPopup');
    if (block){
        block.click();

        document.body.classList.add('body__modal-open');
    } else {
        document.body.classList.remove('body__modal-open');
    }

}