<script setup>
import {useI18n} from "vue-i18n";

defineProps(['show', 'text']);

const emit = defineEmits(['close']);

const { t } = useI18n();

const handleClose = () => {
  emit('close');
};
</script>

<template>
  <div class="job-form-success popup">
    <button
      @click="handleClose"
      class="modal__button-close"
    >
      <img
        src="@/assets/images/close.svg"
        alt="close form"
        class="modal__icon-close"
      >
    </button>

    <h3 class="job-form-success__title">
      {{ t("thankYou") }}
    </h3>

    <p class="job-form-success__text section__text">
      {{ t(text) }}
    </p>
  </div>
</template>

<style scoped>
.job-form-success {
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 700px;
  height: 222px;
  padding: 0 120px;

  border-radius: 30px;
  background-color: #FFF;
}

.job-form-success__title {
  margin-bottom: 10px;

  font-size: 20px;
  font-weight: 700;
  line-height: 145%;
}

.job-form-success__text {
  color: var(--black);
}

@media (max-width: 768px) {
  .job-form-success {
    align-items: center;

    width: 100%;
    height: 222px;

    padding: 0 25px;
  }

  .job-form-success__title {
    margin-bottom: 10px;
  }

  .job-form-success__text {
    text-align: center;
  }
}
</style>
