<script setup>
import {computed, onMounted, onUnmounted, ref} from "vue";
import {useStore} from "vuex";
import axios from "axios";
import {params} from "@/config";
import {useI18n} from "vue-i18n";
import "swiper/css";
import "swiper/css/scrollbar";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Scrollbar } from "swiper";
import CharacteristicsButtons from "@/components/pages/PlanningComparison/Components/CharacteristicsButtons.vue";
import PlaningCard from "@/components/pages/PlanningComparison/Components/PlanningCard.vue";
import AddingPlanning from "@/components/pages/PlanningComparison/Components/AddingPlanning.vue";

const { t } = useI18n();

const store = useStore();
const selectedPlanningsIds = computed(() => store.state.comparisonPlanningsIds);
const selectedPlannings = ref([]);

const getData = () => {
  if (selectedPlanningsIds.value.length) {
    axios.get(`${params.api_url}/plannings/planningsForComparison`, { params: { ids: selectedPlanningsIds.value.join('|') } })
      .then(({ data }) => {
        selectedPlannings.value = data;

        if (data.length > 0) {
          const linkParts = data.map(item => `${item.project_slug}-${item.planning_id}`);
          const comparisonLink = `/comparison/${linkParts.join('-vs-')}`;
          if (window.location.pathname !== comparisonLink) {
            window.location.replace(comparisonLink);
          }
        } else {
          const defaultLink = `/comparison/`;
          if (window.location.pathname !== defaultLink) {
            window.location.replace(defaultLink);
          }
        }
      })
  }
};

const characteristics = ['project_name', 'section', 'building_step', 'square', 'price', 'eoselia', 'rooms_count'];
const initialCharacteristics = computed(() => {
  return localStorage.getItem('comparisonCharacteristics')
      ? JSON.parse(localStorage.getItem('comparisonCharacteristics'))
      : characteristics;
})

const selectedCharacteristics = ref(initialCharacteristics.value);
const selectCharacteristics = (characteristic) => {
  if (selectedCharacteristics.value.includes(characteristic)) {
    selectedCharacteristics.value = selectedCharacteristics.value.filter(item => item !== characteristic);
  } else {
    selectedCharacteristics.value.push(characteristic);
  }

  localStorage.setItem('comparisonCharacteristics', JSON.stringify(selectedCharacteristics.value));
};

const deleteAll = () => {
  selectedPlannings.value = [];
  store.dispatch('deleteAllPlanning');

  const defaultLink = `/comparison/`;
  if (window.location.pathname !== defaultLink) {
    window.location.replace(defaultLink);
  }
};

const deletePlanning = (planning) => {
  store.dispatch('togglePlanningInComparison', planning);

  selectedPlannings.value = selectedPlannings.value.filter(item => item['planning_id'] !== planning['planning_id'])

  if (selectedPlannings.value.length > 0) {
    const linkParts = selectedPlannings.value.map(item => `${item.project_slug}-${item.planning_id}`);
    const comparisonLink = `/comparison/${linkParts.join('-vs-')}`;
    if (window.location.pathname !== comparisonLink) {
      window.location.replace(comparisonLink);
    }
  } else {
    const defaultLink = `/comparison/`;
    if (window.location.pathname !== defaultLink) {
      window.location.replace(defaultLink);
    }
  }
};

const windowWidth = ref(window.innerWidth);

const handleResize = () => {
  windowWidth.value = window.innerWidth;
};

const slidesOnPage = computed(() => {
  if (windowWidth.value < 768) {
    return 1;
  }

  if (windowWidth.value < 1024) {
    return 2;
  }

  return 5;
});

const totalSlidesNum = computed(() => {
  return selectedPlannings.value.length;
});

const initialSlidesNum = computed(() => {
  return selectedPlannings.value.length < slidesOnPage.value ? selectedPlannings.value.length : slidesOnPage.value ;
});

const isNext = ref(totalSlidesNum.value > slidesOnPage.value );
const slideNum = ref(initialSlidesNum.value);
const swiperRef = ref(null);

function onSwiperInit (swiper) {
  swiperRef.value = swiper;
}

function slideToRight() {
  if (swiperRef.value) {
    const nextIndex = swiperRef.value.activeIndex + 1;

    swiperRef.value.slideTo(nextIndex, 400);
  }

  isNext.value = !swiperRef.value.isEnd;
}

onMounted(() => {
  window.addEventListener('resize', handleResize);

  getData();

  if (!localStorage.getItem('comparisonCharacteristics') ) {
    localStorage.setItem('comparisonCharacteristics', JSON.stringify(characteristics));
  }
})

onUnmounted(() => {
  window.removeEventListener('resize', handleResize);
})
</script>

<template>
  <section class="section planning-list">
    <h2 class="section__title planning-list__title">
      {{ t("favoritePlannings") }}
    </h2>

    <CharacteristicsButtons
      :categories="characteristics"
      :selected-categories="selectedCharacteristics"
      @select-category="selectCharacteristics"
    >
      {{ t("characteristic") }}
    </CharacteristicsButtons>

    <div class="planning-list__content">
      <swiper
        @swiper="onSwiperInit"
        :modules="[Scrollbar]"
        slidesPerView="auto"
        :scrollbar="{ draggable: true }"
      >
        <swiper-slide>
          <AddingPlanning
            :selected-characteristics="selectedCharacteristics"
            :characteristics="characteristics"
            @delete-all="deleteAll"
          />
        </swiper-slide>

        <swiper-slide
          v-for="project in selectedPlannings"
          :key="project['project_name']"
        >
          <PlaningCard
            :project="project"
            :selected-characteristics="selectedCharacteristics"
            @delete-planning="deletePlanning"
          />
        </swiper-slide>
      </swiper>

      <svg v-if="selectedPlannings.length >= slidesOnPage" @click="slideToRight" class="planning-list__arrow" width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">-->
        <path class="planning-list__arrow-path" d="M36 72L60 48L36 24" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </div>
  </section>
</template>

<style scoped>
.swiper, .swiper-container {
  width: 100%;
  margin-left: 0;
}

.swiper-slide {
  width: fit-content!important;
  margin: 0!important;
}

.planning-list__title {
  margin-bottom: 40px;
}

.planning-list__content {
  position: relative;
  display: flex;
}

.planning-list__arrow {
  position: absolute;
  top: 300px;
  right: 0;

  cursor: pointer;
  z-index: 1;
}

.planning-list__arrow-path {
  stroke: var(--blago-brown);
  transition: all 0.3s;
}

.planning-list__arrow:hover .planning-list__arrow-path {
  stroke: var(--blago-green);
}

@media (max-width: 768px) {
  .planning-list {
    padding: 0 20px;
  }

  .planning-list__title {
    margin-bottom: 20px;
    text-align: center;
  }

  .planning-list__arrow {
    right: -20px;
  }
}
</style>
