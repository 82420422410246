<script setup>
import {useI18n} from "vue-i18n";

defineProps(['project', 'activeCategory']);

const { t } = useI18n();
</script>

<template>
  <div v-if="activeCategory === 'parking'" class="planing-card__parking">
    <img :src="project['planning_img']" class="planing-card__parking-img">
  </div>

  <div v-else class="planing-card">
    <p class="planing-card__discount">
      {{ (project['is_discount']) ? project['discount_text'] : '' }}
    </p>

    <div>
      <p class="planing-card__text-bold">
        {{ `${project.square} ${t("squareMeasure")}` }}
      </p>

      <p class="planing-card__text">
        {{ project.free_planning ? `${t("free_planning")}` : `${project['rooms_count']}-${t("room")}` }}
      </p>
    </div>

    <p
      :style="{
      background: `
        linear-gradient(90deg, var(--blago-brown) ${project['complete_percent']}%, var(--blago-gray) 0)
      `}"
      class="planing-card__ready"
    >
      {{ `${project['complete_percent']}% ${t("readiness")}` }}
    </p>
  </div>
</template>

<style scoped>
.planing-card__parking {
  width: 90%;
  height: 80%;
}

.planing-card__parking-img {
  height: auto;
  width: 100%;
}

.planing-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  width: 156px;
  height: 157px;

  background-color: var(--blago-white);
  border-radius: 14px;
  overflow: hidden;
  cursor: pointer;
}

.planing-card__discount {
  width: 100%;

  font-size: 16px;
  font-weight: 700;
  line-height: 145%;
  text-align: center;
  background-color: var(--blago-green);
}

.planing-card__text-bold {
  font-size: 25px;
  font-weight: 800;
  line-height: 100%;
  color: var(--black);
  text-align: center;
}

.planing-card__text {
  font-size: 16px;
  font-weight: 400;
  line-height: 145%;
  color: var(--black);
  text-align: center;
}

.planing-card__ready {
  width: 100%;

  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  color: var(--black);
  text-align: center;
}
</style>
