<script setup>
import { RouterLink } from "vue-router";
import {computed, onMounted, onUnmounted, ref} from "vue";
import {useI18n} from "vue-i18n";
import i18n from "@/i18n";
import {Slide} from 'vue3-burger-menu';

const { t } = useI18n();
const show = ref(false);

const locale = computed(() => {
  return i18n.global.locale.value === 'uk' ? '' : i18n.global.locale.value;
});

const isOpenDropdown = ref(false);

const toggleDropdown = () => {
  isOpenDropdown.value = !isOpenDropdown.value;
};

const menuWidth = ref(`${window.innerWidth - 62}`);

const handleResize = () => {
  menuWidth.value = `${window.innerWidth - 62}`;
};

const handleCloseMenu = () => {
  show.value = false;
};

onMounted(() => {
  window.addEventListener('resize', handleResize);
})

onUnmounted(() => {
  window.removeEventListener('resize', handleResize);
});
</script>

<template>
  <div class="menu">
    <div
      v-if="!show"
      @click="show = !show"
      class="menu__icon-wrapper"
    >
      <img
          width="30"
          height="20"
        src="@/assets/images/menu-icon.svg"
        alt="menu"
      >
    </div>

    <div
      v-else
      @click="show = !show"
      class="menu__cross-icon-wrapper menu__icon-wrapper"
    >
      <img
        src="@/assets/images/cross.svg"
        alt="cross"
      >
    </div>

    <Slide
      right
      :isOpen="show"
      @closeMenu="handleCloseMenu"
      :width="menuWidth"
      :burger-icon="false"
      :cross-icon="false"
    >
      <div class="menu__content">
        <RouterLink
            :to="{ name: 'about', params: { locale } }"
            class="section__link menu__link"
        >
          {{ t("aboutUs") }}
        </RouterLink>

        <div class="dropdown">
          <div>
            <div @click="toggleDropdown" class="section__link menu__link dropdown-title">
              {{ t("company") }}

              <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.41169 6.11971C4.73708 6.43852 5.26552 6.43852 5.59091 6.11971L9.75595 2.03899C10.0813 1.72018 10.0813 1.20244 9.75595 0.883636C9.43056 0.56483 8.90212 0.56483 8.57673 0.883636L5 4.38796L1.42327 0.886186C1.09788 0.56738 0.569439 0.56738 0.244045 0.886186C-0.0813484 1.20499 -0.0813484 1.72273 0.244045 2.04154L4.40908 6.12226L4.41169 6.11971Z" fill="#191919"/>
              </svg>
            </div>

            <ul class="dropdown__list" :class="{'dropdown__list--visible': isOpenDropdown}">
              <li class="dropdown__list-item">
                <a
                    href="https://blago-comfort.com.ua/"
                    target="_blank"
                    class="section__link menu__link"
                >
                  blago comfort
                </a>
              </li>
              <li class="dropdown__list-item">
                <a
                    href="https://blagoinvest.com.ua/"
                    target="_blank"
                    class="section__link menu__link"
                >
                  blago invest
                </a>
              </li>
              <li class="dropdown__list-item">
                <RouterLink :to="{ name: 'service', params: { locale } }" class="section__link menu__link">
                  {{ `blago ${t("service")}` }}
                </RouterLink>
              </li>

              <li class="dropdown__list-item">
                <RouterLink :to="{ name: 'family', params: { locale } }" class="section__link menu__link">
                  blago family
                </RouterLink>
              </li>

              <li class="dropdown__list-item">
                <RouterLink :to="{ name: 'app-page', params: { locale } }" class="section__link menu__link">
                  {{ `blago ${t("app")}` }}
                </RouterLink>
              </li>
            </ul>
          </div>
        </div>

        <RouterLink
            :to="{ name: 'projects', params: { locale } }"
            class="section__link menu__link"
        >
          {{ t("projectsTitle") }}
        </RouterLink>

        <RouterLink
            :to="{ name: 'apartments', params: { locale } }"
            class="section__link menu__link"
        >
          {{ t("readyForRepair") }}
        </RouterLink>

        <RouterLink
            :to="{ name: 'filter', params: { locale } }"
            class="section__link menu__link"
        >
          {{ t("apartmentsFilter") }}
        </RouterLink>

        <RouterLink
            :to="{ name: 'news', params: { locale } }"
            class="section__link menu__link"
        >
          {{ t("news") }}
        </RouterLink>

        <!--        <RouterLink-->
        <!--          :to="{ name: 'blog', params: { locale } }"-->
        <!--          class="section__link menu__link"-->
        <!--        >-->
        <!--          {{ t("blog") }}-->
        <!--        </RouterLink>-->

        <RouterLink
            :to="{ name: 'contacts', params: { locale } }"
            class="section__link menu__link"
        >
          {{ t("contactsTitle") }}
        </RouterLink>
      </div>
    </Slide>
  </div>
</template>

<style scoped>
.dropdown-title {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;

  text-decoration: none;
  border-radius: 24px;

  cursor: pointer;
}

.dropdown__list {
  display: none;
  padding: 0 0 0 20px;
  list-style: none;
}

.dropdown__list--visible {
  display: block;
}

.menu__content {
  display: flex;
  flex-direction: column;

  padding: 22px 36px 0 0;
  background-color: var(--blago-green);
  z-index: 11;
}

.menu__icon-wrapper {
  cursor: pointer;
}

.menu__cross-icon-wrapper {
  position: absolute;
  top: 0;
  right: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 70px;
  height: 62px;
  background-color: var(--blago-green);

  z-index: 10;
}

.menu__link {
  display: block;
  color: var(--blago-black);
  line-height: 365%;
  text-decoration: none;
}
</style>
