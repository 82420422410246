<script setup>
import {ref} from "vue";
import {useI18n} from "vue-i18n";

defineProps(['show']);

const emit = defineEmits(['close']);
const { t } = useI18n();
const phoneNumber = ref('+380');

const handleClose = () => {
  emit('close');
};

const handleSubmit = () => {

};
</script>

<template>
  <Transition v-if="show" name="modal">
    <div class="overlay" @click="handleClose">
      <div class="callback-popup popup" @click.stop>
        <button
            @click="handleClose"
            class="modal__button-close"
        >
          <img
            src="@/assets/images/close.svg"
            alt="close form"
            class="modal__icon-close"
          >
        </button>

        <div class="callback-popup__wrapper">
          <h3 class="section__link callback-popup__title">
            {{ t("callPopupTitle") }}
          </h3>

          <form
            @submit.prevent="handleSubmit"
            class="contact-form callback-popup__form"
          >
            <label class="callback-popup__label">
              <input
                type="tel"
                name="phone"
                required
                v-model="phoneNumber"
                v-mask="'+38 (0##) ###-##-##'"
                class="contact-form__field callback-popup__input"
              >
            </label>
            <button class="contact-form__button">
              {{ t("waitForCall") }}
            </button>
          </form>
        </div>

        <div class="callback-popup__text-with-logo">
          {{ t("virtual") }}
        </div>
      </div>
    </div>
  </Transition>
</template>

<style scoped>
.callback-popup {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  width: 720px;
  height: 380px;
  padding: 20px;

  border-radius: 30px;
  background-color: var(--blago-white);
}

.callback-popup__wrapper {
  margin: auto;
}

.callback-popup__title {
  margin-bottom: 30px;

  text-align: center;
}

.callback-popup__input {
  padding-left: 58px;
  border: 1px solid #E0E0E0;
}

.callback-popup__label {
  position: relative;
}

.callback-popup__label::before {
  content: url("@/assets/images/flag.svg");

  display: block;
  width: 16px;
  height: 10px;

  position: absolute;
  top: 50%;
  left: 18px;

  transform: translateY(-100%);
}

.callback-popup__label::after {
  content: url("@/assets/images/arrow-down-for-form.svg");

  display: block;
  width: 10px;
  height: 3px;

  position: absolute;
  top: 25%;
  left: 40px;

  transform: translateY(-50%);
}

.callback-popup__text-with-logo {
  align-self: flex-end;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;

  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  color: #737272;
}

.callback-popup__text-with-logo::after {
  content: url("@/assets/images/Phonet.png");

  width: 48px;
  height: 12px;
}

@media (max-width: 768px) {
  .callback-popup {
    width: 390px;
    max-height: 280px;
    padding: 64px 20px 20px;
  }

  .callback-popup__close {
    position: absolute;
  }

  .callback-popup__title {
    margin-bottom: 20px;
  }

  .callback-popup__form {
    margin-bottom: 20px;
  }

  .callback-popup__input {
    display: block;
    width: 276px;
    height: 44px;

    border-radius: 22px;
    background: rgba(255, 255, 255, 0.95);
  }

  .callback-popup__label::before {
    content: url("@/assets/images/flag.svg");

    display: block;
    width: 16px;
    height: 10px;

    position: absolute;
    top: 50%;
    left: 18px;

    transform: translateY(-100%);
  }

  .callback-popup__label::after {
    content: url("@/assets/images/arrow-down-for-form.svg");

    display: block;
    width: 10px;
    height: 3px;

    position: absolute;
    top: 11px;
    left: 40px;

    transform: translateY(-50%);
  }
}
</style>
