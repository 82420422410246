<script setup>
import {computed, ref, watchEffect} from "vue";
import {useI18n} from "vue-i18n";
import axios from "axios";
import {params} from "@/config";
import SubscriptionPopup from "@/components/SubscriptionPopup.vue";
import JobForm from "@/components/JobForm.vue";
import i18n from "@/i18n";

const { t } = useI18n();
const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

const showForm = ref(false);
const showSubscriptionForm = ref(false);

const openForm = () => {
  showForm.value = true;
  document.body.classList.add('body__modal-open');
};

const closeForm = () => {
  showForm.value = false;
  document.body.classList.remove('body__modal-open');
};

const openSubscriptionForm = () => {
  showSubscriptionForm.value = true;
  document.body.classList.add('body__modal-open');
};

const closeSubscriptionForm = () => {
  showSubscriptionForm.value = false;
  document.body.classList.remove('body__modal-open');
};

const currentYear = computed(() => {
  return new Date().getFullYear();
});

const formattedPhoneNum = (phone) => {
  return ('' + phone).replace(/\D/g, '');
};

const lang = computed(() => {
  return i18n.global.locale.value;
});

const locale = computed(() => {
  return i18n.global.locale.value === 'uk' ? '' : i18n.global.locale.value;
});

const footerData = ref({});

watchEffect(() => {
  getData();
})

async function getData() {
  await axios.get(`${params.api_url}/pages/footer`, { params: {lang: lang.value }})
    .then(({ data }) => {
      footerData.value = data;
    })
}
</script>

<template>
  <footer class="footer">
    <div class="footer__content">
      <div class="footer__block">
        <a :href="locale ? `/${locale}` : '/'">
          <img
            src="@/assets/images/black-logo.svg"
            alt="logo"
            class="footer__logo"
            width="115"
            height="26"
          >
        </a>

        <p class="footer__copyright">
          {{ `Blago © ${currentYear} Copyright` }}
        </p>

        <h4 class="footer__title">
          {{ t("follow") }}
        </h4>
        
        <div class="footer__links-wrapper">
          <a
            v-for="social in footerData['social_networks']"
            :href="social.link"
            :style="{'background': `url(${social.logo}) center/cover, no-repeat`}"
            target="_blank"
            class="social-link footer__social-link"
          ></a>
        </div>

        <button
          @click="openSubscriptionForm"
          class="footer__button button-green footer__button--mb-40"
        >
          {{ t("signUp") }}
        </button>

        <Teleport to="body">
          <SubscriptionPopup
            :show="showSubscriptionForm"
            @close="closeSubscriptionForm"
          />
        </Teleport>

        <h4 v-if="footerData.app" class="footer__title">
          {{ t("download_our_app") }}
        </h4>

        <div v-if="footerData.app" class="footer__links-wrapper footer__links-wrapper--mb-0">
          <a :href="footerData.app['play_market']" target="_blank">
            <img src="@/assets/images/GooglePlay.png" alt="Google Play">
          </a>

          <a :href="footerData.app['app_store']" target="_blank">
            <img src="@/assets/images/Appstore.png" alt="App Store">
          </a>
        </div>
      </div>

      <div class="footer__block-on-desktop">
        <h4 class="footer__title">
          {{ t("contactsTitle") }}
        </h4>

        <div class="footer__contact-element">
          <p class="section__text footer__text footer__text--transparent">
            {{ `${t("phone")}:` }}
          </p>

          <a href="tel:+380970714000"
            class="section__text footer__text ph-phone1"
          >
            +380970714000
          </a>
        </div>

        <div class="footer__contact-element">
          <p class="section__text footer__text footer__text--transparent">
            Viber:
          </p>

          <a
            :href="`viber://chat?number=%2B${formattedPhoneNum(footerData['viber_phone_number'])}`"
            class="section__text footer__text"
          >
            {{ footerData['viber_phone_number'] }}
          </a>
        </div>

        <div class="footer__contact-element">
          <p class="section__text footer__text footer__text--transparent">
            Email:
          </p>

          <a
            :href="`mailto:${footerData['email']}`"
            class="section__text footer__text"
          >
            {{ footerData['email'] }}
          </a>
        </div>
      </div>

      <div class="footer__block">
        <div class="footer__info-block">
          <h4 class="footer__title">
            {{ t("interestedInWork") }}
          </h4>

          <p class="footer__text footer__text--transparent">
            {{ `${t("hrDepartment")}:` }}
          </p>

          <a
            :href="`tel:+${formattedPhoneNum(footerData['hr_department_phone_number'])}`"
            class="section__text footer__text ph-phone1"
          >
            {{ footerData['hr_department_phone_number'] }}
          </a>

          <button
            @click="openForm"
            class="section__text footer__text footer__text--with-underline"
          >
            {{ t("feedbackForm") }}
          </button>

          <Teleport to="body">
            <JobForm
              :show="showForm"
              @close="closeForm"
            />
          </Teleport>
        </div>

        <div>
          <h4 class="footer__title">
            {{ t("customerSupport") }}
          </h4>

          <p class="section__text footer__text footer__text--transparent">
            {{ `blago ${t("service")}:` }}
          </p>

          <a
            :href="`tel:+${formattedPhoneNum(footerData['blago_service_phone_number'])}`"
            class="section__text footer__text ph-phone1"
          >
            {{ footerData['blago_service_phone_number'] }}
          </a>
        </div>
      </div>

      <div class="footer__block">
        <div class="footer__awards">
          <h4 class="footer__title">
            {{ t("ourAwards") }}
          </h4>

          <img
            src="@/assets/images/medals_last.png"
            alt="medals"
            class="footer__medals"
            loading="lazy"
          >
        </div>

        <div>
          <h4 class="footer__title">
            {{ t("partner") }}
          </h4>

          <img
            src="@/assets/images/partner.svg"
            alt="Save Ukraine Now"
            class="footer__partner-icon"
          >
        </div>
      </div>

      <button
        @click="scrollToTop"
        class="footer__arrow-up footer__title"
      >
        {{ t("up") }}
      </button>
    </div>
  </footer>
</template>

<style scoped>
  .footer {
    min-height: 500px;

    background-color: var(--alternative-brown);
  }

  .footer__content {
    position: relative;

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 80px 190px 40px;
  }

  .footer__logo {
    width: 126px;
    height: 28px;
    margin-bottom: 5px;
  }

  .footer__copyright {
    margin-bottom: 18px;

    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    opacity: 0.25;
  }

  .footer__title {
    margin-bottom: 10px;
    
    font-size: 16px;
    font-weight: 700;
    line-height: 145%;
    color: var(--black);
  }

  .footer__links-wrapper {
    display: flex;
    gap: 12px;
    margin-bottom: 20px;
  }

  .footer__social-link {
    opacity: 0.5;
  }

  .footer__social-link:hover {
    opacity: 1;
  }

  .footer__button {
    width: 200px;
    height: 54px;
    margin-bottom: 20px;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    font-size: 16px;
    font-weight: 700;
    line-height: 145%;
    background-color: var(--blago-green);
    border-radius: 30px;

    transition: background-color 0.3s;
  }

  .footer__button:hover {
    background-color: transparent;
  }

  .footer__button::before {
    content: url("@/assets/images/envelope.svg");

    width: 24px;
    height: 16px;
  }

  .footer__text {
    display: block;
    margin-bottom: 2px;

    color: var(--black);
    text-decoration: none;
  }

  .footer__text--with-underline {
    text-decoration: underline;
    border: none;
    background: none;
    cursor: pointer;
  }

  .footer__text--transparent {
    color: var(--blago-black);
    opacity: 0.5;
  }

  .footer__contact-element {
    margin-bottom: 10px;
  }

  .footer__info-block {
    margin-bottom: 20px;
  }

  .footer__awards {
    margin-bottom: 54px;
  }

  .footer__medals {
    width: 208px;
    height: 54px;
  }

  .footer__partner-icon {
    width: 243px;
    height: 28px;
  }

  .footer__arrow-up {
    position: absolute;
    bottom: -48px;
    left: 50%;

    color: var(--blago-black);
    background-color: var(--alternative-brown);
    border: none;
    text-decoration: none;

    transform: translateX(-50%);
    cursor: pointer;
  }

  .footer__arrow-up::before {
    content: url("@/assets/images/arrow-up.png");

    display: block;
    text-align: center;
  }

  .footer__arrow-up:hover::before {
    content: url("@/assets/images/arrow-up-hover.svg");
  }

  @media (max-width: 1024px) {
    .footer__content {
      flex-direction: column;
      align-items: center;
      padding: 60px 40px;
    }

    .footer__copyright {
      margin-bottom: 20px;
    }

    .footer__info-block {
      margin-bottom: 40px;
    }

    .footer__block {
      display: flex;
      flex-direction: column;
      align-items: center;

      margin-bottom: 40px;
    }

    .footer__block-on-desktop {
      display: none;
    }

    .footer__title {
      text-align: center;
    }

    .footer__text {
      text-align: center;
    }

    .footer__text--with-underline {
      margin: 0 auto;
    }

    .footer__partner-icon {
      margin-bottom: 20px;
    }

    .footer__arrow-up {
      bottom: 40px;
      transform: translateX(-50%);
    }

    .footer__links-wrapper--mb-0 {
      margin-bottom: 0;
    }

    .footer__button--mb-40 {
      margin-bottom: 40px;
    }
  }

  @media (max-width: 768px) {
    .footer__content {
      padding: 55px 20px 60px;
    }
  }
</style>
