<script setup>
import {ref} from "vue";
import TopAction from "@/components/TopAction.vue";
import CallButton from "@/components/CallButton.vue";
import CallbackPopup from "@/components/CallbackPopup.vue";

defineProps(['isCallButton']);
const showCard = ref(false);

const openCard = () => {
  showCard.value = true;
  document.body.classList.add('body__modal-open');
};

const closeCard = () => {
  showCard.value = false;
  document.body.classList.remove('body__modal-open');
};
</script>

<template>
  <header class="header">
    <TopAction />

    <CallButton v-if="isCallButton" />

<!--    <Teleport to="body">-->
<!--      <CallbackPopup-->
<!--        @close="closeCard"-->
<!--        :show="showCard"-->
<!--      />-->
<!--    </Teleport>-->
  </header>
</template>

<style scoped>
.header {
  height: 210px;

  margin-bottom: 28px;

  background-image: url("@/assets/images/header-photo2.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
</style>
