<script setup>
import {computed, onMounted, onUnmounted, ref} from "vue";
import "swiper/css";
import "swiper/css/navigation";
import SwiperCore, {Keyboard, Mousewheel} from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import PagesNum from "@/components/PagesNum.vue";
import ButtonLeft from "@/components/ButtonLeft.vue";
import ButtonRight from "@/components/ButtonRight.vue";
import ApartmentCard from "@/components/pages/Home/Components/ApartmentCard.vue";
import ProjectsSlideForMobile from "@/components/ProjectsSlideForMobile.vue";
import getProjectsForSlider from "@/helpers/getProjectsForSlider";

SwiperCore.use([Keyboard, Mousewheel]);
const props = defineProps(['projects']);

const windowWidth = ref(window.innerWidth);

const handleResize = () => {
  windowWidth.value = window.innerWidth;
};

const totalSlidesNum = computed(() => {
  return props.projects.length;
});

const slidesOnPage = computed(() => {
  if (windowWidth.value < 1024) {
    return 2;
  }

  return 4;
});

const initialSlidesNum = computed(() => {
  return props.projects.length && props.projects.length < slidesOnPage.value ? props.projects.length : slidesOnPage.value;
});

onMounted(() => {
  window.addEventListener('resize', handleResize);
})

onUnmounted(() => {
  window.removeEventListener('resize', handleResize);
})

const projectsForMobile = computed(() => getProjectsForSlider(props.projects, 2));

const isNext = ref(totalSlidesNum.value > slidesOnPage.value);
const isPrev = ref(false);
const slideNum = ref(initialSlidesNum.value);
const swiperRef = ref(null);

function onSwiperInit (swiper) {
  swiperRef.value = swiper;
}

const updateNum = () => {
  if (windowWidth.value > 767) {
    slideNum.value = swiperRef.value.realIndex + slidesOnPage.value;
  } else {
    if ((swiperRef.value.realIndex + 1) * slidesOnPage.value > totalSlidesNum.value) {
      slideNum.value = totalSlidesNum.value;
    } else {
      slideNum.value = (swiperRef.value.realIndex + 1) * slidesOnPage.value;
    }
  }
};

function slideToRight() {
  if (swiperRef.value) {
    const nextIndex = swiperRef.value.activeIndex + 1;

    swiperRef.value.slideTo(nextIndex, 300);
  }

  isNext.value = !swiperRef.value.isEnd;
  isPrev.value = !swiperRef.value.isBeginning;
}

function slideToLeft() {
  if (swiperRef.value) {
    const prevIndex = swiperRef.value.activeIndex - 1;

    swiperRef.value.slideTo(prevIndex, 300);
  }

  isNext.value = !swiperRef.value.isEnd;
  isPrev.value = !swiperRef.value.isBeginning;
}
</script>

<template>
  <div class="slider projects-slider">
    <div class="slider__flex-container projects-slider__flex-container">
      <PagesNum
        v-if="projects.length > slidesOnPage"
        :slide-num="slideNum"
        :total-slides-num="totalSlidesNum"
      />

      <div class="slider__buttons-wrapper projects-slider__buttons-wrapper">
        <ButtonLeft
          v-if="projects.length > slidesOnPage"
          @click="slideToLeft"
          :is-active="isPrev"
        />

        <ButtonRight
          v-if="projects.length > slidesOnPage"
          @click="slideToRight"
          :is-active="isNext"
        />
      </div>
    </div>
  </div>

  <div class="slider__content">
    <Swiper
      :breakpoints="{
        1024: {
          slidesPerView: 4,
          spaceBetween: 34
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 34
        },
        0: {
          slidesPerView: 1
        }
      }"
      :keyboard="{
        enabled: true,
        onlyInViewport: true,
        pageUpDown: true,
      }"
      @swiper="onSwiperInit"
      @slide-change="updateNum"
      class="slider__slides projects-slider__slides"
    >

      <SwiperSlide
        v-if="windowWidth > 767"
        v-for="project in projects"
        :key="project.id"
      >
        <ApartmentCard :apartment="project" class="projects-slider__card" />
      </SwiperSlide>

      <SwiperSlide
        v-else
        v-for="projectsArr in projectsForMobile"
      >
        <ProjectsSlideForMobile :projects-arr="projectsArr" />
      </SwiperSlide>
    </Swiper>
  </div>
</template>

<style scoped>
.projects-slider {
  margin-bottom: 40px;
}

.projects-slider__flex-container {
  position: absolute;
  top: -56px;
  right: 0;
}

.projects-slider__buttons-wrapper {
  display: flex;
  gap: 15px;

  margin-right: 16px;
}

.projects-slider__slides {
  display: flex;
  gap: 62px;
}

.projects-slider__card {
  width: auto;
}

@media (max-width: 1024px) {
  .projects-slider {
    flex-direction: column;
    gap: 23px;

    margin-bottom: 34px;
  }

  .projects-slider__flex-container {
    position: static;
    gap: 15px;
  }
}

</style>
