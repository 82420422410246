<script setup>
import {useI18n} from "vue-i18n";
import {computed} from "vue";
import i18n from "@/i18n";

const props = defineProps(['selectedCharacteristics', 'characteristics']);
const emit = defineEmits(['deleteAll']);

const { t } = useI18n();

const locale = computed(() => {
  return i18n.global.locale.value === 'uk' ? '' : i18n.global.locale.value;
});
const handleDeleteAll = () => {
  emit('deleteAll');
};
</script>

<template>
  <div class="adding-planning">
    <a
      :href="locale ? `/${locale}/plannings` : '/plannings'"
      class="adding-planning__link"
    >
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path class="adding-planning__icon" d="M12 8V16M8 12H16M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>

      {{ t("add") }}
      <br />
      {{ t("planning") }}
    </a>

    <button @click="handleDeleteAll" class="adding-planning__button">
      {{ t("deleteAll") }}
    </button>

    <ul class="adding-planning__list">
      <li
        v-for="characteristic in selectedCharacteristics"
        :key="characteristic"
        class="adding-planning__item"
      >
        {{ characteristic === 'price' ? t("cost") : t(characteristic) }}
      </li>
    </ul>
  </div>
</template>

<style scoped>
.adding-planning {
  margin-bottom: 40px;
}

.adding-planning__link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  width: 160px;
  height: 237px;
  margin: 0 20px 8px 0;

  font-size: 16px;
  font-weight: 700;
  line-height: 145%;

  color: var(--alternative-brown);
  border-radius: 8px;
  border: 2px solid var(--alternative-brown);
  background: var(--blago-white);
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}

.adding-planning__link:hover {
  color: var(--blago-black);
  border-color: var(--blago-green);
}

.adding-planning__icon {
  stroke: var(--alternative-brown);
}

.adding-planning__link:hover .adding-planning__icon {
  stroke: var(--blago-green);
}

.adding-planning__button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;

  margin-bottom: 20px;
  padding: 8px 16px;

  font-size: 12px;
  font-weight: 400;
  color: rgba(25, 25, 25, 0.25);
  border-radius: 18px;
  border: 2px solid var(--blago-gray);
  background-color: var(--blago-white);

  cursor: pointer;
  transition: all 0.3s;
}

.adding-planning__button:hover {
  color: var(--blago-black);
  border-color: var(--blago-green);
}

.adding-planning__button::after {
  content: url("@/assets/images/XCircle.svg");
  display: flex;
  justify-content: center;
  align-items: center;
}

.adding-planning__button:hover::after {
  content: url("@/assets/images/XCircleBlack.svg");
}

.adding-planning__list {
  padding: 0;
  list-style: none;
}

.adding-planning__item {
  height: 44px;
  margin-bottom: 20px;
  padding: 10px 0;

  font-size: 16px;
  font-weight: 700;
  line-height: 145%;
  color: var(--blago-black);
  border-bottom: 1px solid var(--blago-gray);
}

@media (max-width: 768px) {
  .adding-planning__link {
    width: 126px;
  }
}
</style>
