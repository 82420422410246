<script setup>
import {computed, ref} from "vue";
import {useI18n} from "vue-i18n";
import ContactsTable from "@/components/ContactsTable.vue";
import ContactsButtons from "@/components/ContactsButtons.vue";
import Map from "@/components/Map.vue";

const { t } = useI18n();

const activeMap = ref('projects');
const maps = [
  {
    name: 'Проєкти',
    url: '../src/assets/images/map.png',
  },
  {
    name: 'Інфраструктура',
    url: '../src/assets/images/map.png',
  },
  {
    name: 'Аеро-тур',
    url: '../src/assets/images/map.png',
  },
];

const selectMap = (text) => {
  activeMap.value = text;
};

const currentMap = computed(() => {
  return maps.find(map => map.name === activeMap.value);
});
</script>

<template>
  <section class="section contacts">
    <div class="contacts__content">
      <div class="contacts__wrapper">
        <div>
          <h2 class="section__title contacts__title">
            {{ t("contactsTitle")}}
          </h2>

          <ContactsTable />
        </div>

        <div class="contacts__map-wrapper">
          <ContactsButtons
            @select-map="selectMap"
            :active-map="activeMap"
          />

          <Map :active-map="activeMap" />
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
  .contacts__content {
    padding: 0 35px 0 0;
    margin-bottom: 40px;
  }

  .contacts__wrapper {
    display: flex;
    justify-content: center;
    gap: 52px;
    margin-bottom: 45px;
  }

  .contacts__title {
    margin-bottom: 40px;
  }

  @media (max-width: 1024px) {
    .contacts__content {
      padding: 0 40px 0 0;
    }

    .contacts__wrapper {
      flex-direction: column-reverse;
      align-items: center;
      margin-bottom: 40px;
    }

    .contacts__title {
      margin-bottom: 20px;
      text-align: center;
    }

    .contacts__map-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  @media (max-width: 768px) {
    .contacts__content {
      padding: 0 20px;
    }
  }
</style>
