<script setup>
import {computed} from "vue";
import {useI18n} from "vue-i18n";
import i18n from "@/i18n";
import router from "@/router";
import {useRoute} from "vue-router";

const props = defineProps(['activeMap']);

const { t } = useI18n();

const locale = computed(() => {
  return i18n.global.locale.value === 'uk' ? '' : i18n.global.locale.value;
});

const route = useRoute();

const handleSaveRoute = () => {
  localStorage.setItem('prevRoute', JSON.stringify(route.path));
};
</script>

<template>
  <div  class="map-overlay">
    <div class="map">
      <img
        src="https://blagodeveloper.com/wp-content/uploads/2024/01/map-1.png.webp"
        alt="map"
        class="map__photo"
        width="1113"
        height="671"
        loading="lazy"
      >

      <a
        :href="locale ? `/${locale}/map?type=${activeMap}` : `/map?type=${activeMap}`"
        @click="handleSaveRoute"
        class="map__button"
      >
        {{ t("viewMap") }}
      </a>
    </div>
  </div>
</template>

<style scoped>
.map {
  position: relative;
  height: 671px;
}

.map::before {
  content: "";
  transition: background-color 0.3s;
}

.map:hover::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  z-index: 1;
  border-radius: 30px;
  background-color: rgba(0, 0, 0, 0.65);
}

.map__photo {
  position: relative;
  width: 100%;
  height: 100%;

  object-position: center;
  object-fit: cover;

  border-radius: 30px;
}

.map__button {
  display: none;

  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
  padding: 13px 23px;

  font-size: 16px;
  font-weight: 700;
  line-height: 145%;
  color: var(--blago-black);
  background-color: var(--blago-green);
  border: 2px solid var(--blago-green);
  border-radius: 25px;
  text-decoration: none;
  text-align: center;
  cursor: pointer;

  transition: all 0.3s;
  z-index: 1;
}

.map:hover .map__button {
  display: block;
}

.map__button:hover {
  color: var(--blago-white);
  background-color: transparent;

  z-index: 2;
}

@media (max-width: 1024px) {
  .map__button {
    display: block;
  }
}

@media (max-width: 768px) {
  .map {
    height: 210px;
  }

  .map__button {
    display: block;
    max-width: 204px;
    padding: 13px 23px;

    left: 50%;
    right: -50%;

    transform: translate(-50%, -50%);

    font-size: 16px;
    font-weight: 700;
    line-height: 145%;
    background-color: var(--blago-green);
    border: 2px solid var(--blago-green);
    border-radius: 25px;
  }
}
</style>
