<script setup>
import {computed, ref} from "vue";
import {useI18n} from "vue-i18n";
import FormSuccess from "@/components/FormSuccess.vue";

defineProps(['show']);

const emit = defineEmits(['close']);

const { t } = useI18n();

const candidateName = ref('');
const phone = ref('');
const position = ref('');
const about = ref('');
const isError = ref(false);
const isSuccess = ref(false);

const placeholder = computed(() => t("typeInformation"));
const requiredFieldText = computed(() => t("requiredField"));
const submittingError = computed(() => t("jobFormErrorMessage"));

const handleClose = () => {
  emit('close');

  candidateName.value = '';
  phone.value = '';
  position.value = '';
  about.value = '';

  isSuccess.value = false;
  isError.value = false;
};

const handleSubmit = () => {
  if (!candidateName.value || !phone.value || !position.value) {
    isError.value = true;
  } else {
    isError.value = false;
    isSuccess.value = true;

    candidateName.value = '';
    phone.value = '';
    position.value = '';
    about.value = '';
  }
};
</script>

<template>
  <Transition v-if="show" name="modal">
    <div class="overlay" @click="handleClose">
      <form
        v-if="!isSuccess"
        @submit.prevent="handleSubmit"
        @click.stop
        class="job-form popup"
      >
        <button
          @click="handleClose"
          class="modal__button-close"
        >
          <img
            src="@/assets/images/close.svg"
            alt="close form"
            class="modal__icon-close"
          >
        </button>

        <label class="job-form__label">
        <span
          :data-content="requiredFieldText"
          :class="{ 'job-form__title--error': isError && !candidateName }"
          class="section__link job-form__title"
        >
          {{ t("name") }}
        </span>

          <input
            v-model="candidateName"
            type="text"
            name="Name"
            :placeholder="placeholder"
            :class="{ 'job-form__field--error': isError && !candidateName }"
            class="job-form__field"
          >
        </label>

        <label>
        <span
          :data-content="requiredFieldText"
          :class="{ 'job-form__title--error': isError && !phone }"
          class="section__link job-form__title"
        >
          {{ t("phone") }}
        </span>

          <input
            v-model="phone"
            v-mask="'+38 (0##) ###-##-##'"
            type="tel"
            name="Phone"
            :placeholder="placeholder"
            :class="{ 'job-form__field--error': isError && !phone }"
            class="job-form__field"
          >
        </label>

        <label>
        <span
          :data-content="requiredFieldText"
          :class="{ 'job-form__title--error': isError && !position }"
          class="section__link job-form__title"
        >
          {{ t("desiredPosition") }}
        </span>

          <input
            v-model="position"
            type="text"
            name="Position"
            :placeholder="placeholder"
            :class="{ 'job-form__field--error': isError && !position }"
            class="job-form__field"
          >
        </label>

        <p class="section__link job-form__title">
          {{ t("jobFormAbout") }}
        </p>

        <textarea
          v-model="about"
          cols="56"
          rows="6"
          :placeholder="placeholder"
          class="job-form__field job-form__textarea"
        ></textarea>

        <div
          :data-content="submittingError"
          :class="{ 'job-form__button-wrapper--error': isError }"
        >
          <button
            class="section__link button-green job-form__button"
          >
            {{ t("send") }}
          </button>
        </div>
      </form>

      <FormSuccess
        v-else
        @close="handleClose"
        text="jobFormSuccess"
      />
    </div>
  </Transition>
</template>

<style scoped>
.job-form {
  width: 700px;
  height: 668px;
  margin: 0 auto;
  padding: 80px 120px;

  border-radius: 30px;
  background-color: var(--blago-white);
}

.job-form__title {
  display: flex;
  justify-content: space-between;

  margin-bottom: 5px;
  padding-left: 16px;

  line-height: normal;
}

.job-form__title--error::after {
  content: attr(data-content);

  margin-right: 16px;
  font-size: 12px;
  font-weight: 400;
  color: #FF9292;
}

.job-form__field {
  display: block;
  width: 100%;
  margin-bottom: 20px;
  padding: 10px 16px;

  border-radius: 22px;
  border: 1px solid #E0E0E0;
  background: rgba(255, 255, 255, 0.95);
}

.job-form__field::placeholder {
  font-family: 'Manrope', sans-serif;

  font-size: 16px;
  font-weight: 400;
  line-height: 145%;
  color: var(--blago-black);
  opacity: 0.25;
}

.job-form__field--error {
  border: 1px solid #FF9292;
}

.job-form__textarea {
  margin-bottom: 40px;
}

.job-form__button-wrapper--error {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.job-form__button-wrapper--error::after {
  content: attr(data-content);

  margin-right: 16px;
  font-size: 12px;
  font-weight: 400;
  color: #FF9292;
}

.job-form__button {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 188px;
  height: 44px;

  border-radius: 22px;
}

@media (max-width: 768px) {
  .job-form {
    width: 100%;
    height: 668px;
    padding: 80px 25px;
  }

  .job-form__title--error {
    position: relative;
  }

  .job-form__title--error::after {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 60%;

    margin-right: 0;
    
    text-align: right;
  }

  .job-form__button-wrapper--error {
    flex-direction: column;
    justify-content: left;
    align-items: flex-start;
  }
}
</style>
