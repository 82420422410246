<script setup>
import {useI18n} from "vue-i18n";
const props = defineProps(['activeCategory', 'categories']);
const emit = defineEmits(['updateCategory']);

const { t } = useI18n();

const handleClick = (category) => {
  emit('updateCategory', category);
}
</script>

<template>
  <nav class="projects__nav">
    <button
      v-for="categoryItem in categories"
      :key="categoryItem"
      @click="handleClick(categoryItem)"
      :class="{ 'projects__button--active': activeCategory === categoryItem }"
      class="projects__button"
    >
      {{ categoryItem === 'parking' ? t(categoryItem) : categoryItem.split(' ')[1] ? `${t(categoryItem.split(' ')[0])} ${categoryItem.split(' ')[1]}` : categoryItem }}
    </button>
  </nav>
</template>

<style scoped>
</style>
