<script setup>
import {computed, ref} from "vue";
import {useI18n} from "vue-i18n";
import FormSuccess from "@/components/FormSuccess.vue";
import axios from "axios";
import {params} from "@/config";

defineProps(['show']);

const emit = defineEmits(['close']);

const { t } = useI18n();

const name = ref('');
const phone = ref('');
const complexes = ref('');

const isError = ref(false);
const isSuccess = ref(false);
const placeholder = computed(() => t("typeInformation"));
const requiredFieldText = computed(() => t("requiredField"));

const handleClose = () => {
  emit('close');

  name.value = '';
  phone.value = '';
  complexes.value = '';

  isSuccess.value = false;
  isError.value = false;
};

const handleSubmit = () => {
  if (!name.value || !phone.value) {
    isError.value = true;
  } else {
    axios.post(params.api_url+'/system/sendMessageToBot',{
      form_name:'Обрати квартиру (CustomForm)',
      phone_number:phone.value,
      interest_projects:complexes.value,
      client_name:name.value,
      site_url:'',
      utm:window.location.search

    })

    isError.value = false;
    isSuccess.value = true;

    name.value = '';
    phone.value = '';
    complexes.value = '';
  }
};
</script>

<template>
  <Transition v-if="show" name="modal">
    <div class="overlay" @click="handleClose">
      <form
        v-if="!isSuccess"
        @submit.prevent="handleSubmit"
        @click.stop
        class="connect-form popup"
      >
        <h3 class="connect-form__title">
          {{ t("fillInForm") }}
        </h3>

        <button
          @click="handleClose"
          class="modal__button-close"
        >
          <img
            src="@/assets/images/close.svg"
            alt="close form"
            class="modal__icon-close"
          >
        </button>

        <label class="connect-form__label">
        <span
          :data-content="requiredFieldText"
          :class="{ 'connect-form__title--error': isError && !name }"
          class="section__link connect-form__field-title"
        >
          {{ `${t("yourName")}*` }}
        </span>

          <input
            v-model="name"
            type="text"
            name="Name"
            :placeholder="placeholder"
            :class="{ 'connect-form__field--error': isError && !name }"
            class="connect-form__field"
          >
        </label>

        <label>
        <span
          :data-content="requiredFieldText"
          :class="{ 'connect-form__title--error': isError && !phone }"
          class="section__link connect-form__field-title"
        >
          {{ `${t("yourPhone")}*` }}
        </span>

          <input
            v-model="phone"
            v-mask="'+38 (0##) ###-##-##'"
            type="tel"
            name="Phone"
            :placeholder="placeholder"
            :class="{ 'connect-form__field--error': isError && !phone }"
            class="connect-form__field"
          >
        </label>

        <label>
        <span
          class="section__link connect-form__field-title"
        >
          {{ t("whichComplexesInterested") }}
        </span>

          <input
            v-model="complexes"
            type="text"
            name="Complexes"
            :placeholder="placeholder"
            class="connect-form__field"
          >
        </label>

        <div>
          <button class="section__link button-green connect-form__button">
            {{ t("sign_up_for_a_visit") }}
          </button>
        </div>
      </form>

      <FormSuccess
          v-else
          @close="handleClose"
          text="connectFormSuccess"
      />
    </div>
  </Transition>
</template>

<style scoped>
.connect-form {
  width: 600px;
  margin: 0 auto;
  padding: 80px;

  border-radius: 30px;
  background-color: var(--blago-white);
}

.connect-form__title {
  margin-bottom: 20px;

  font-weight: 700;
  text-align: center;
}

.connect-form__title--error::after {
  content: attr(data-content);

  margin-right: 16px;
  font-size: 12px;
  font-weight: 400;
  color: #FF9292;
}

.connect-form__field-title {
  display: flex;
  justify-content: space-between;

  margin-bottom: 5px;
  padding-left: 16px;

  line-height: normal;
}

.connect-form__field {
  display: block;
  width: 100%;
  margin-bottom: 20px;
  padding: 10px 16px;

  border-radius: 22px;
  border: 1px solid #E0E0E0;
  background: rgba(255, 255, 255, 0.95);
}

.connect-form__field--error {
  border: 1px solid #FF9292;
}

.connect-form__field::placeholder {
  font-family: 'Manrope', sans-serif;

  font-size: 16px;
  font-weight: 400;
  line-height: 145%;
  color: var(--blago-black);
  opacity: 0.25;
}



@media (max-width: 768px) {
  .connect-form {
    width: 100%;
    padding: 80px 25px;
  }

  .connect-form__button {
    width: 100%;
  }

  .connect-form__title--error {
    position: relative;
  }

  .connect-form__title--error::after {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 60%;

    margin-right: 0;

    text-align: right;
  }
}
</style>
