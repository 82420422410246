const getProjectsForSlider = (projects, slidesNum) => {
    const result = [];

    for (let i = 0; i < projects.length; i += slidesNum) {
        const currentArr = projects.slice(i, i + slidesNum);

        result.push(currentArr);
    }

    return result;
};

export default getProjectsForSlider;
