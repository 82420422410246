<script setup>
import router from "@/router";
import {computed} from "vue";
import {useI18n} from "vue-i18n";
import i18n from "@/i18n";
import {useRoute} from "vue-router";

defineProps(['activeMap']);
const emit = defineEmits(['selectMap']);
const { t } = useI18n();

const newCategory = computed(() => t("newCategory"));

const handleClick = (text) => {
  emit('selectMap', text);
};

const locale = computed(() => {
  return i18n.global.locale.value === 'uk' ? '' : i18n.global.locale.value;
});

const route = useRoute();

const handleSaveRoute = () => {
  localStorage.setItem('prevRoute', JSON.stringify(route.path));
};
</script>

<template>
  <div class="contacts-buttons">
    <button
      @click="handleClick('projects')"
      :class="{ 'contacts-buttons__item--active': activeMap === 'projects' }"
      class="contacts-buttons__item"
    >
      {{ t("projectsTitle") }}
    </button>

    <a
        :href="locale ? `/${locale}/map?type=infrastructure` : '/map?type=infrastructure'"
        @click="handleSaveRoute"
        :data-content="newCategory"
        :class="{'contacts-buttons__item--active': activeMap === 'infrastructure'}"
        class="contacts-buttons__item contacts-buttons__item--new"
    >
      {{ t("infrastructure") }}
    </a>

    <a
        :href="locale ? `/${locale}/map?type=flay_tour` : '/map?type=flay_tour'"
        @click="handleSaveRoute"
        :data-content="newCategory"
        class="contacts-buttons__item contacts-buttons__item--new contacts-buttons__item--with-icon"
    >
      {{ t("aeroTour") }}
    </a>
  </div>
</template>

<style scoped>
.contacts-buttons {
  display: flex;
  justify-content: right;
  gap: 10px;
  margin-bottom: 40px;
}

.contacts-buttons__item {
  width: 147px;
  height: 58px;

  font-size: 16px;
  font-weight: 700;
  line-height: 145%;
  border-radius: 30px;
  cursor: pointer;

  background-color: var(--alternative-brown);
  border: 2px solid var(--alternative-brown);

  transition: all 0.3s;
}

.contacts-buttons__item--new {
  position: relative;
  width: 213px;

  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.contacts-buttons__item--new::after {
  content: attr(data-content);

  position: absolute;
  top: -10px;
  right: 0;

  padding: 1px 10px;
  font-size: 12px;
  font-weight: 800;
  line-height: 145%;
  background-color: var(--blago-green);
  border-radius: 10px;
}

.contacts-buttons__item:hover {
  border-color: var(--blago-green);
  background-color: var(--blago-gray);
}

.contacts-buttons__item:active {
  border-color: var(--blago-green);
  background-color: var(--blago-gray);
}

.contacts-buttons__item--active {
  background-color: var(--blago-white);
  border: 2px solid var(--blago-green);
}

.contacts-buttons__item--with-icon {
  display: flex;
  justify-content: center;
  align-items: center;

  gap: 5px;
}

.contacts-buttons__item--with-icon::before {
  content: url("@/assets/images/drone.png");
  width: 39px;
  height: 18px;
}

@media (max-width: 1396px) {
  .contacts-buttons {
    flex-wrap: wrap;
  }
}

@media (max-width: 1024px) {
  .contacts-buttons {
    justify-content: center;
    flex-wrap: wrap;
    gap: 13px;
    margin-bottom: 20px;
  }

  .contacts-buttons__item {
    align-self: flex-end;
    width: 120px;
    height: 36px;
  }

  .contacts-buttons__item--new {
    width: 160px;
  }
}
</style>
