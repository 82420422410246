<script setup>
import {computed} from "vue";
import i18n from "@/i18n";
import {useI18n} from "vue-i18n";
import ProjectsSlider from "@/components/ProjectsSlider.vue";

defineProps(['projects']);

const { t } = useI18n();

const locale = computed(() => {
  return i18n.global.locale.value === 'uk' ? '' : i18n.global.locale.value;
});
</script>

<template>
  <section class="section projects">
    <div class="projects__content">
      <div class="projects__wrapper">
        <h2 class="section__title projects__title">
          {{ t("projectsTitle") }}
        </h2>

        <a
          :href="locale ? `/${locale}/projects` : '/projects'"
          class="projects__link"
        >
          {{ t("allProjects") }}
        </a>
      </div>

      <ProjectsSlider :projects="projects" />
    </div>
  </section>
</template>

<style scoped>
.projects__wrapper {
  display: flex;
  align-items: center;
}

.projects__title::after {
  content: url("@/assets/images/icon3.svg");

  display: inline-block;
  width: 48px;
  height: 36px;
  margin: 0 20px 0 28px;
}

.projects__link {
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  color: var(--blago-black);
  text-decoration: none;
}

@media (max-width: 1024px) {
  .projects {
    padding: 0 40px;
  }

  .projects__wrapper {
    justify-content: center;
    margin-bottom: 23px;
  }

  .projects__title::after {
    content: url("@/assets/images/icon3-for-mobile.svg");

    width: 24px;
    height: 18px;
    margin: 0 10px;
  }
}

@media (max-width: 768px) {
  .projects {
    padding: 0 20px;
  }
}
</style>
